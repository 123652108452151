import React from 'react'
import propTypes from 'prop-types'

export default function InlineSvg({ content, cssClass }) {
  return <div className={cssClass} dangerouslySetInnerHTML={{ __html: content }} />
}

InlineSvg.propTypes = {
  content: propTypes.string.isRequired,
  cssClass: propTypes.string
}