import React, { Component } from 'react'
import * as styles from './section.module.css'
import classNames from 'classnames'
class Section extends Component {
  render() {
    const { children, className, minPadding } = this.props
    const classes = {
      [className]: className,
      [styles.section]: true,
      [styles.minPadding]: minPadding,
    }
    return <div className={classNames(classes)}>{children}</div>
  }
}

export default Section
