import React from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Callout from '../components/callout'
import Section from '../components/section'
import PageHeader from '../components/pageHeader'
import * as styles from './styles/careers.module.css'
import ArrowDown from '../components/icons/ArrowDown'
import LearnMoreButton from '../components/learnMoreButton'
import BackgroundWrapper from '../components/backgroundWrapper'
import FeatureCard from '../components/featureCard'
import Card from '../components/card'
import Benefit from '../components/benefit'
import SynapseTeam from '../static/SynapseTeam'
import LargeHexPattern from '../components/icons/LargeHexPattern'
import CareersFeelCollage from '../components/svgs/CareersFeelCollage'
import { getTextCopy, getHtmlCopy } from '../helpers'

const Careers = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
    callout,
  } = data.careersPage
  const cards = data.careersFeatureCards.nodes
  const benefits = data.careersBenefits.nodes
  const jobPostings = data.careersJobPostings.nodes
  const { jobPostingPageSlug } = data.jobPostingPage

  const handleClick = e => {
    navigate(
      `${pageSlug}#${getTextCopy(content, 'page.careers.openPositionsHash')}`
    )
    e.target.blur()
  }

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <Section>
          <PageHeader
            header={getTextCopy(content, 'page.careers.header')}
            subheader={getHtmlCopy(content, 'page.careers.subheader')}
          />
          <div
            onClick={handleClick}
            className={styles.heroButton}
            role="button"
            tabIndex="0"
            onKeyDown={handleClick}
          >
            <span className={styles.heroButtonText}>
              {getTextCopy(content, 'page.careers.openPositions')}
            </span>
            <ArrowDown />
          </div>
        </Section>

        <BackgroundWrapper color="#F7FAFC">
          <Section>
            <div className={styles.leftAlignedSection}>
              <div className={styles.leftAlignedSectionContent}>
                <h3
                  className={`${styles.h3} ${styles.primary}`}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.challengedHeader')
                      .replace(`{{highlightStart}}`, `<strong>`)
                      .replace(`{{highlightEnd}}`, `</strong>`),
                  }}
                />
                <div
                  className={styles.p}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.challengedBody'),
                  }}
                />
                <h3
                  className={`${styles.h3} ${styles.secondary}`}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.productiveHeader')
                      .replace(`{{highlightStart}}`, `<strong>`)
                      .replace(`{{highlightEnd}}`, `</strong>`),
                  }}
                />
                <div
                  className={styles.p}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.productiveBody'),
                  }}
                />
                <h3
                  className={`${styles.h3} ${styles.tertiary}`}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.supportedHeader')
                      .replace(`{{highlightStart}}`, `<strong>`)
                      .replace(`{{highlightEnd}}`, `</strong>`),
                  }}
                />
                <div
                  className={styles.p}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.supportedBody'),
                  }}
                />
              </div>
              <div className={styles.careersFeelCollageContainer}>
                <CareersFeelCollage />
              </div>
            </div>
          </Section>
        </BackgroundWrapper>

        <div className={styles.waveBackgroundWrapper}>
          <Section>
            <div className={styles.rightAlignedSection}>
              <div className={styles.teamIconContainer}>
                <SynapseTeam />
              </div>
              <div className={styles.rightAlignedSectionContent}>
                <h3 className={styles.h3}>
                  <strong>{getTextCopy(content, 'page.careers.teamHeader')}</strong>
                </h3>
                <div
                  className={styles.p}
                  dangerouslySetInnerHTML={{
                    __html: getHtmlCopy(content, 'page.careers.teamBody'),
                  }}
                />
              </div>
            </div>
          </Section>
        </div>

        <BackgroundWrapper color="#EDF2F7">
          <div className={styles.abstractBackgroundWrapper}>
            <div className={styles.section}>
              <h2 className={styles.h2}>
                {getTextCopy(content, 'page.careers.featuresHeader')}
              </h2>
              <div className={styles.cardWrapper}>
                {cards.map(card => (
                  <FeatureCard
                    key={card.header.child.html}
                    icon={card.icon.svg.content}
                    header={card.header.child.html}
                    subheader={card.subheader.child.html}
                    fontSize={22}
                  />
                ))}
              </div>
            </div>
          </div>
        </BackgroundWrapper>

        <BackgroundWrapper color="#323237">
          <div className={styles.benefitsSectionWrapper}>
            <div className={styles.benefitsSection}>
              <LargeHexPattern />
              <h2 className={styles.benefitsHeading}>
                {getTextCopy(content, 'page.careers.benefitsHeader')}
              </h2>
              <div
                className={styles.benefitsSubHeading}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.careers.benefitsSubheader'),
                }}
              />
              <div className={styles.benefitsGrid}>
                {benefits.map(benefit => (
                  <Benefit
                    key={benefit.title}
                    header={benefit.title}
                    subheader={benefit.description.child.html}
                    icon={benefit.icon.file.url}
                  />
                ))}
              </div>
            </div>
          </div>
        </BackgroundWrapper>

        <div
          id={getTextCopy(content, 'page.careers.openPositionsHash')}
          className={styles.jobSection}
        >
          <Section>
            <h1 className={styles.h1}>
              {getTextCopy(content, 'page.careers.jobPostingsHeader')}
            </h1>
            <div className={styles.jobPostingsGrid}>
              {jobPostings.map(jobPosting => (
                <Card
                  key={jobPosting.slug}
                  className={styles.postWrapper}
                  linkTo={jobPostingPageSlug.replace(
                    `{{slug}}`,
                    jobPosting.slug
                  )}
                >
                  <h3 className={styles.postTitle}>{jobPosting.title}</h3>
                  <p className={styles.snippet}>{jobPosting.excerpt}</p>
                  <LearnMoreButton>
                    {getTextCopy(content, 'page.careers.jobPostingApply')}
                  </LearnMoreButton>
                </Card>
              ))}
            </div>
          </Section>
        </div>
      </Layout>
    </>
  )
}

export default Careers

export const query = graphql`
  {
    careersPage: contentfulPage(name: { eq: "page.careers" }) {
      ...pageFields
    }
    careersFeatureCards: allContentfulFeatureCard(
      filter: { type: { eq: "careers" } }
    ) {
      nodes {
        header {
          child: childMarkdownRemark {
            html
          }
        }
        subheader {
          child: childMarkdownRemark {
            html
          }
        }
        icon {
          svg {
            content
          }
        }
      }
    }
    careersBenefits: allContentfulBenefit {
      nodes {
        title
        description {
          child: childMarkdownRemark {
            html
          }
        }
        icon {
          file {
            url
          }
        }
      }
    }
    careersJobPostings: allContentfulJobPosting {
      nodes {
        slug
        title
        excerpt
      }
    }
    jobPostingPage: contentfulPage(name: { eq: "page.jobPosting" }) {
      jobPostingPageSlug: slug
    }
  }
`
