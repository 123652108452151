import React from 'react'

const SynapseTeam = () => {
  return (
    <svg width="487" height="313" xmlns="http://www.w3.org/2000/svg" className="synapseTeamSvg">
      <g fillRule="nonzero" fill="none">
        <ellipse fill="#F7FAFC" cx="370.5" cy="164.5" rx="110.5" ry="113.5" />
        <ellipse className="secondary" cx="60" cy="43.5" rx="42" ry="43.5" />
        <ellipse fill="#EDF2F7" cx="135.5" cy="118.5" rx="110.5" ry="113.5" />
        <ellipse fill="#CBD5E0" cx="129.5" cy="232.5" rx="121.5" ry="6.5" />
        <ellipse
          fill="#000"
          opacity=".1"
          cx="105.5"
          cy="233"
          rx="90.5"
          ry="5"
        />
        <ellipse fill="#CBD5E0" cx="365.5" cy="279.5" rx="121.5" ry="7.5" />
        <ellipse
          fill="#000"
          opacity=".1"
          cx="340.5"
          cy="279.5"
          rx="90.5"
          ry="5.5"
        />
        <ellipse fill="#CBD5E0" cx="174.5" cy="262" rx="77.5" ry="4" />
        <ellipse fill="#CBD5E0" cx="37.5" cy="248" rx="37.5" ry="2" />
        <ellipse fill="#CBD5E0" cx="413.5" cy="309.5" rx="69.5" ry="3.5" />
        <path fill="#E2E8F0" d="M267 194h139v85H267z" />
        <ellipse className="primary" cx="312" cy="237.5" rx="31" ry="32.5" />
        <path className="primary" d="M353 207h11v12h-11z" />
        <path className="primary" opacity=".5" d="M366 207h11v12h-11z" />
        <path className="primary" opacity=".2" d="M378 207h11v12h-11z" />
        <path fill="#2F2E41" d="M353 220h11v12h-11z" />
        <path fill="#2F2E41" opacity=".5" d="M366 220h11v12h-11z" />
        <path fill="#2F2E41" opacity=".2" d="M378 220h11v12h-11z" />
        <path className="tertiary" d="M353 234h11v12h-11z" />
        <path className="tertiary" opacity=".5" d="M366 234h11v12h-11z" />
        <path className="tertiary" opacity=".2" d="M378 234h11v12h-11z" />
        <path
          d="M311 205v65c17.733 0 33-14.55 33-32.5S328.733 205 311 205z"
          fill="#3F3D56"
          opacity=".5"
        />
        <path
          d="M435.806 200s18.327 8.412 11.782 18.153c-6.546 9.74-26.182 28.336-26.618 32.32-.436 3.985-3.054 7.527-3.054 7.527s-8.727-9.298-7.855-10.183c.873-.886 9.6-9.298 10.036-11.512.437-2.213 13.527-19.48 13.527-19.48l-11.781-10.627L435.806 200z"
          fill="#FFB9B9"
        />
        <path
          d="M435.806 200s18.327 8.412 11.782 18.153c-6.546 9.74-26.182 28.336-26.618 32.32-.436 3.985-3.054 7.527-3.054 7.527s-8.727-9.298-7.855-10.183c.873-.886 9.6-9.298 10.036-11.512.437-2.213 13.527-19.48 13.527-19.48l-11.781-10.627L435.806 200z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M412 218.685s12.429 5.349 11.571 13.817c-.857 8.468 15 32.535 15 32.535s1.715 5.794 1.286 7.576C439.43 274.396 451 273.95 451 273.95v-4.01s-9-16.045-9.857-20.502c-.857-4.457-6-21.839-6-21.839S433.429 212 424.857 212c-5.058 0-9.822 2.477-12.857 6.685z"
          fill="#FFB9B9"
        />
        <path
          d="M413.176 179s26.692 22.148 25.802 23.956c-.89 1.808-13.79 8.587-16.015 9.04C420.739 212.446 405 179 405 179h8.176z"
          fill="#2F2E41"
        />
        <path
          d="M413.176 179s26.692 22.148 25.802 23.956c-.89 1.808-13.79 8.587-16.015 9.04C420.739 212.446 405 179 405 179h8.176z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M384.48 182.064s-2.61 12.308 4.348 17.322C395.787 204.4 407.53 224 408.834 224c1.305 0 20.006-10.484 19.137-12.307-.87-1.823-10.438-17.777-10.438-17.777l-3.045-16.866s-26.53-.911-30.009 5.014z"
          fill="#2D3748"
        />
        <path
          d="M413 143l4.113 26.218 7.313 18.533s10.968 11.3 4.57 12.204c-6.398.904-10.055-12.204-10.055-12.204L413 176.902V143z"
          fill="#FFB9B9"
        />
        <path
          d="M413 143l4.113 26.218 7.313 18.533s10.968 11.3 4.57 12.204c-6.398.904-10.055-12.204-10.055-12.204L413 176.902V143z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M398.461 114s0 9.288-3.057 10.173c-3.057.885 12.229 12.827 12.229 12.827s-1.747-15.48 4.367-19.462L398.461 114z"
          fill="#FFB9B9"
        />
        <path
          d="M398.461 114s0 9.288-3.057 10.173c-3.057.885 12.229 12.827 12.229 12.827s-1.747-15.48 4.367-19.462L398.461 114z"
          fill="#000"
          opacity=".1"
        />
        <circle fill="#FFB9B9" cx="405" cy="112" r="10" />
        <path
          d="M406.274 133.102s-9.163-11.533-10.472-11.09c-1.309.444-10.909 10.203-11.781 13.308-.873 3.105-2.618 25.283-1.31 29.72 1.31 4.435 1.31 15.968.873 16.855-.436.887-3.054 3.105-.436 3.105s11.781-6.654 17.89-5.323c6.11 1.33 14.4-.443 14.836-1.33.436-.888-.436-23.954 0-25.728.436-1.774-.873-10.646-1.309-12.42-.436-1.774-.436-8.871-4.8-10.646l-1.86-.187-.105 4.388-1.526-.652z"
          className="primary"
        />
        <path
          d="M387 157s.868 13.782 7.38 19.561c6.512 5.78 20.405 15.56 21.273 17.339.868 1.778 9.117 11.114 10.854 7.113a6.8 6.8 0 0 0 .348-3.942c-.51-2.652-2.066-4.97-4.3-6.408l-3.863-2.543-19.102-20.45-.868-10.67H387z"
          fill="#FFB9B9"
        />
        <path
          d="M412.373 246.195s-5.102-1.123-6.876 2.022c-1.775 3.145 1.774 11.68 1.774 11.68s10.204 21.565 11.979 18.87c1.775-2.696-1.33-12.58-1.33-12.58s4.436-9.884 2.661-10.333c-1.775-.449-8.208-4.268-8.208-9.659zM442.545 271.695s-2.636-1.385-3.075 0c-.44 1.386-3.076 8.314 0 9.238 3.075.924 33.39 1.848 32.511 0-.878-1.847-5.711-4.157-8.787-5.08-3.075-.924-10.983-7.853-10.983-7.853s-4.394 6.005-9.666 3.695z"
          fill="#1A202C"
        />
        <path
          d="M414.746 103.745a2.719 2.719 0 0 0-1.137-1.545 6.508 6.508 0 0 0-.24-2.04c-.626-1.925-2.108-1.815-3.506-2.666-.607-.37-.57-.696-.885-1.302a4.748 4.748 0 0 0-1.789-1.9c-.897-.596-1.844-1.209-2.905-1.284-2.23-.158-3.94 2.026-6.071 2.727-.94.309-1.95.321-2.894.613-.944.292-1.887.99-2.028 2.005-.065.469.05.95-.016 1.418-.13.931-.916 1.584-1.441 2.35-1.162 1.694-1.029 4.087-.1 5.93.196.381.42.746.67 1.091a6.92 6.92 0 0 0 .707 2.493c.93 1.844 2.528 3.22 4.198 4.365a10.715 10.715 0 0 1-.247-1.861c-.024-.21.002-.42.076-.617.186-.405.677-.512 1.052-.734.776-.46 1.08-1.475 1.103-2.404.022-.93-.167-1.86-.074-2.784.008-.43.19-.837.5-1.12.287-.17.614-.25.944-.233 1.59-.068 3.251-.098 4.654-.881a6.797 6.797 0 0 1 1.449-.763c1.191-.324 2.41.546 3.097 1.61.687 1.065 1.03 2.332 1.663 3.432.634 1.1 1.729 2.08 2.954 1.95.14 0 .273-.061.366-.17a.59.59 0 0 0 .068-.324l.068-4.877a7.662 7.662 0 0 0-.236-2.479z"
          fill="#2F2E41"
        />
        <path
          d="M391.326 133s9.852 4.478 9.404 13.882c-.448 9.404 2.687 10.3-4.926 11.195-7.613.896-9.404 0-9.852.896-.447.895-3.134-20.6-1.343-22.838 1.791-2.24 6.717-3.135 6.717-3.135z"
          className="primary"
        />
        <ellipse fill="#2F2E41" cx="199" cy="42.5" rx="14" ry="14.5" />
        <path
          d="M209.638 200l-7.794 22.232s-6.496 5.445-2.599 7.26c3.898 1.815 11.692-1.815 11.692-1.815L217 211.343 216.134 200h-6.496z"
          fill="#2F2E41"
        />
        <path
          d="M209.638 200l-7.794 22.232s-6.496 5.445-2.599 7.26c3.898 1.815 11.692-1.815 11.692-1.815L217 211.343 216.134 200h-6.496z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M209.07 71l6.783.876s3.166 24.086 1.809 25.838c-1.356 1.752-8.14 8.759-10.852 7.007C204.097 102.97 209.07 71 209.07 71z"
          fill="#575A89"
        />
        <path
          d="M146.42 115s-9.026 4.278-10.745 4.754c-1.72.475-4.299 5.228-1.29 6.18 3.009.95 14.615-8.557 14.615-8.557L146.42 115z"
          fill="#A0616A"
        />
        <path
          d="M212 209.065l4.757 18.619s.432 6.811 2.594 7.265c2.163.454 8.649-2.27 8.649-3.178 0-.909-2.595-8.174-2.595-8.174s0-5.45-2.594-7.72c-2.595-2.27-4.932-9.877-4.932-9.877L212 209.065z"
          fill="#2F2E41"
        />
        <path
          d="M217.92 166.451c.889 2.1.779 17.33-.194 27.465-.47 4.925-1.144 8.647-2.006 9.084l-6.305-1.596-2.495-.633-.066-5.544-.374-30.558-7.48-31.646V125l18.92 1.783 3 4.773.08.13s-4.4 31.645-3.08 34.765z"
          fill="#2D3748"
        />
        <path
          d="M214.014 176.257c.945 1.43 2.522 9.998 3.78 17.66-.472 4.924-1.148 8.646-2.014 9.083l-6.328-1.596c-.097-.205-.194-.419-.295-.633-.809-1.711-1.568-3.6-2.275-5.544l-.375-30.558L199 133.023V125l18.988 1.783 3.012 4.773-17.584-1.207 3.974 17.382 4.858 18.275s0 7.577 1.766 10.251z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M184.025 107.837s-5.95 13.918 2.55 33.224a405.59 405.59 0 0 1 14.025 36.817s2.975 14.816 7.225 24.244c4.25 9.429 3.4 9.878 3.4 9.878l6.8-4.04s-3.825-27.838-5.525-30.531c-1.7-2.694-1.7-10.327-1.7-10.327l-4.675-18.408-3.825-17.51 18.7 1.347s0-25.592-8.5-30.531l-28.475 5.837z"
          fill="#2D3748"
        />
        <path
          d="M190.808 50s0 7.149-2.11 7.596c-2.11.447-6.33.447-7.596 2.68C179.836 62.512 190.808 92 190.808 92l11.394-7.596L206 64.298s-5.486-3.575-5.486-5.362v-8.042L190.808 50z"
          fill="#A0616A"
        />
        <path
          d="M190.808 50s0 7.149-2.11 7.596c-2.11.447-6.33.447-7.596 2.68C179.836 62.512 190.808 92 190.808 92l11.394-7.596L206 64.298s-5.486-3.575-5.486-5.362v-8.042L190.808 50z"
          fill="#000"
          opacity=".1"
        />
        <circle fill="#A0616A" cx="196.5" cy="46.5" r="10.5" />
        <path
          d="M190.63 83.552S186.713 58 185.843 58c-.87 0-13.056 2.69-13.926 3.138-.87.448-3.917 4.483-3.917 4.483l6.093 17.93s2.61 12.552 4.351 14.794c1.741 2.241 5.223 8.517 3.482 9.414-1.74.896-3.482 2.241-1.74 2.241 1.74 0 16.101-2.69 16.971-1.345.87 1.345 15.232-4.93 15.232-5.38 0-.447-1.74-21.516-1.74-21.516L215 71.449l-13.056-9.415-11.314 21.518z"
          fill="#4A5568"
        />
        <path
          d="M172.944 63l-4.367 2.262s-12.225 28.952-13.098 29.405c-.873.452-5.24 9.047-5.676 10.857-.437 1.81-1.31 7.69-2.62 8.595-.703.494-1.431.948-2.183 1.357 0 0 2.62 4.524 3.93 4.524s5.676-6.333 5.676-6.333 9.605 6.333 12.225 1.357C169.451 110.048 176 78.38 176 78.38L172.944 63z"
          fill="#4A5568"
        />
        <path
          d="M207 77.072s-6.317-15.776-9.926-13.92c-3.61 1.856 2.707 12.992 6.768 14.848l3.158-.928z"
          fill="#A0616A"
        />
        <path
          d="M200.762 76.45l6.255-1.45 1.324 6.935s5.796 10.512 4.459 15.083c-1.338 4.571-9.363 11.884-12.038 7.77-2.675-4.113-4.904-10.512-3.121-14.169 1.783-3.656 4.458-12.341 3.12-14.17z"
          fill="#000"
          opacity=".1"
        />
        <path
          d="M202.762 75.45l6.255-1.45 1.324 6.935s5.796 10.512 4.459 15.083c-1.338 4.571-9.363 11.884-12.038 7.77-2.675-4.113-4.904-10.512-3.121-14.169 1.783-3.656 4.458-12.341 3.12-14.17z"
          fill="#4A5568"
        />
        <ellipse
          fill="#2F2E41"
          transform="rotate(-64.899 199.577 40.466)"
          cx="199.577"
          cy="40.466"
          rx="5.116"
          ry="10.474"
        />
        <ellipse fill="#2F2E41" cx="207.5" cy="31" rx="6.5" ry="6" />
        <path
          d="M202.362 29c0-3.064 2.431-5.636 5.638-5.964-2.406-.25-4.75.829-6.036 2.778a5.748 5.748 0 0 0 0 6.372c1.285 1.949 3.63 3.028 6.036 2.778-3.207-.328-5.637-2.9-5.638-5.964z"
          fill="#2F2E41"
        />
        <path fill="#CBD5E0" d="M29 124h151v109H29z" />
        <path fill="#A0AEC0" d="M40 135h20v20H40z" />
        <path className="primary" d="M40 168h20v20H40z" />
        <path fill="#A0AEC0" d="M40 202h20v20H40zM69 141h96v2H69z" />
        <path fill="#F2F2F2" d="M71 147h29v2H71zM69 175h96v2H69z" />
        <path fill="#A0AEC0" d="M71 180h29v2H71z" />
        <path fill="#F2F2F2" d="M69 208h96v2H69z" />
        <path fill="#A0AEC0" d="M71 214h29v2H71z" />
        <path
          d="M430 298.705c0 9.113-5.148 12.295-11.5 12.295a19.277 19.277 0 0 1-1.315-.048c-5.732-.426-10.185-3.772-10.185-12.247 0-8.769 10.65-19.835 11.452-20.656l.001-.001.047-.048s11.5 11.593 11.5 20.705z"
          className="primary"
        />
        <path
          d="M417.315 309.611l3.926-6.184-3.936 6.863-.01.71a15.076 15.076 0 0 1-.816-.042l.423-9.118-.003-.072.007-.012.04-.862-3.946-6.88 3.958 6.234.01.183.32-6.89-3.379-7.11 3.42 5.902.333-14.284V278v.048l-.055 11.263 3.363-4.465-3.377 5.435-.089 6.169 3.141-5.92-3.153 6.828-.05 3.43 4.558-8.241-4.576 9.436-.11 7.628z"
          fill="#3F3D56"
        />
        <path
          d="M48 237.705C48 246.818 42.852 250 36.5 250a19.277 19.277 0 0 1-1.315-.048C29.453 249.526 25 246.18 25 237.705c0-8.769 10.65-19.835 11.452-20.656l.001-.001.047-.048S48 228.593 48 237.705z"
          className="primary"
        />
        <path
          d="M37.315 248.611l3.926-6.184-3.936 6.863-.01.71a15.076 15.076 0 0 1-.816-.042l.423-9.118-.003-.072.007-.012.04-.862-3.946-6.88 3.958 6.234.01.183.32-6.89-3.379-7.11 3.42 5.902.333-14.284V217v.048l-.055 11.263 3.363-4.465-3.377 5.435-.089 6.169 3.141-5.92-3.153 6.828-.05 3.43L42 231.546l-4.576 9.436-.11 7.628z"
          fill="#3F3D56"
        />
      </g>
    </svg>
  )
}

export default SynapseTeam
