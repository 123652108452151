import React from 'react'

const BackgroundWrapper = ({ children, color }) => {
  const bgColor = color ? color : '#F7FAFC'
  return (
    <div style={{ backgroundColor: bgColor }}>
      {children}
    </div>
  )
}

export default BackgroundWrapper
