import React from 'react'
import * as styles from './hex-animation.module.css'
import HexagonIcon from '../icons/Hexagon'

const classes = [styles.spinUp, styles.spinDown]

const HexAnimation = () => {
  return (
    <div className={styles.hexAnimationContainer}>
      <HexagonIcon
        size={Math.floor(Math.random() * 48) + 32}
        className={classes[Math.floor(Math.random() * classes.length)]}
        style={{
          top: `-${Math.floor(Math.random() * 5) + 1}px`,
          left: `${Math.floor(Math.random() * 40) + 10}px`,
          order: `${Math.floor(Math.random() * 3) + 1}`,
          animationDuration: `${Math.floor(Math.random() * 40) + 10}s`,
        }}
      />
      <HexagonIcon
        size={Math.floor(Math.random() * 24) + 18}
        className={classes[Math.floor(Math.random() * classes.length)]}
        style={{
          top: `${Math.floor(Math.random() * 12) + 1}px`,
          left: `${Math.floor(Math.random() * 40) + 10}px`,
          order: `${Math.floor(Math.random() * 3) + 1}`,
          animationDuration: `${Math.floor(Math.random() * 20) + 10}s`,
        }}
      />
      <HexagonIcon
        size={Math.floor(Math.random() * 32) + 24}
        className={classes[Math.floor(Math.random() * classes.length)]}
        style={{
          top: `-${Math.floor(Math.random() * 15) + 1}px`,
          left: `${Math.floor(Math.random() * 40) + 10}px`,
          order: `${Math.floor(Math.random() * 3) + 1}`,
          animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
        }}
      />
    </div>
  )
}

export default HexAnimation
