import React from 'react'
import * as styles from './benefit.module.css'

const index = ({ header, subheader, icon }) => {
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.icon} src={icon} alt={`${header} icon`} />
      </div>
      <div>
        <h3 className={styles.h3}>{header}</h3>
        <div
          className={styles.p}
          dangerouslySetInnerHTML={{ __html: subheader }}
        />
      </div>
    </div>
  )
}

export default index
