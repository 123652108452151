import React from 'react'
import InlineSvg from '../inlineSvg'
import * as styles from './feature-card.module.css'

const index = ({ header, subheader, icon, fontSize }) => {
  return (
    <div className={styles.card}>
      <InlineSvg content={icon} cssClass={styles.icon} />
      <div
        className={styles.h3}
        dangerouslySetInnerHTML={{ __html: header }}
        style={{ fontSize: fontSize ? fontSize : '24px' }}
      />
      <div
        className={styles.p}
        dangerouslySetInnerHTML={{ __html: subheader }}
      />
    </div>
  )
}

export default index
