import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './learn-more-button.module.css'
import classNames from 'classnames'
import Arrow from '../icons/Arrow'
import { getTextCopy } from '../../helpers'

const LearnMoreButton = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    {
      learnMoreButtonComponent: contentfulComponent(
        name: { eq: "component.learnMoreButton" }
      ) {
        ...componentFields
      }
    }
  `)
  const { content } = data.learnMoreButtonComponent

  const buttonClasses = {
    [styles.learnMoreButton]: true,
    [className]: className,
  }

  return (
    <button className={classNames(buttonClasses)}>
      <span>
        {children
          ? children
          : getTextCopy(content, 'component.learnMoreButton.default')}
      </span>{' '}
      <Arrow />
    </button>
  )
}

export default LearnMoreButton
