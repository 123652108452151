import React from 'react'
import HexAnimation from '../hexAnimation'
import * as styles from './page-header.module.css'

const PageHeader = ({ header, subheader }) => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.content}>
        <h3>{header}</h3>
        <div dangerouslySetInnerHTML={{ __html: subheader }} />
      </div>
      <div className={styles.animation}>
        <HexAnimation />
      </div>
    </div>
  )
}

export default PageHeader
