import React from "react";

const CareersFeelCollage = ({
  firstBg = "#CDE1FF",
  secondBg = "#DFFFF7",
  thirdBg = "#FFC6C6",
  darkShirt = "#3F3D56",
  lightShirt = "#E6E6E6",
  coloredShirt = "#6C63FF",
  pantsColor = "#2F2E41",
  lightSkinTone = "#FFB6B6",
  darkSkinTone = "#9F616A",
}) => {
  return (
    <svg
      width="702"
      height="813"
      viewBox="0 0 702 813"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={firstBg}
        d="M40.3758 150.684L150.684 40.3758L301.369 80.7516L341.745 231.436L231.436 341.745L80.7515 301.369L40.3758 150.684Z"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M230.909 298.518C229.592 300.293 227.958 301.81 226.09 302.993C225.693 303.245 225.285 303.482 224.87 303.702H215.108C215.224 303.468 215.338 303.231 215.448 302.993C218.475 296.611 219.424 289.442 218.162 282.493C216.9 275.544 213.49 269.165 208.412 264.252C214.313 266.073 219.767 269.624 223.335 274.659C225.068 277.11 226.301 279.88 226.963 282.808C227.552 276.952 226.615 271.043 224.243 265.655C229.034 269.549 232.764 274.882 234.186 280.884C235.608 286.89 234.576 293.554 230.909 298.518Z"
          fill="#F0F0F0"
        />
        <path
          d="M234.865 304H143.465V303.254H235L234.865 304Z"
          fill={darkShirt}
        />
        <path
          d="M111.489 122.281C111.489 135.972 106.702 147.661 97.5914 156.359C91.1266 162.535 81.1688 171.191 81.1688 180.343V187.915H50.8486V180.343C50.8486 171.095 40.8518 162.422 34.426 156.359C25.5214 147.966 20.5283 135.483 20.5283 122.281V122.001C20.5674 109.951 25.3956 98.4091 33.9509 89.9151C42.5062 81.4211 54.0879 76.6701 66.1488 76.7072C78.2096 76.7444 89.7619 81.5665 98.2646 90.113C106.767 98.6596 111.524 110.231 111.489 122.281Z"
          fill={coloredShirt}
        />
        <path
          d="M81.1685 188.978C80.8863 188.978 80.6157 188.866 80.4162 188.667C80.2167 188.467 80.1046 188.197 80.1046 187.915V180.342C80.1046 171.44 88.8918 163.129 95.3084 157.061C95.8403 156.557 96.3584 156.069 96.8556 155.592C105.731 147.114 110.423 135.596 110.423 122.282C110.423 97.3982 90.9142 77.9058 66.0094 77.9058H65.8839C54.1634 77.9189 42.9249 82.5681 34.6256 90.837C26.3264 99.1058 21.6415 110.322 21.5954 122.032V122.28C21.5954 135.319 26.5385 147.459 35.1579 155.588C35.5945 156 36.048 156.424 36.5137 156.86C43.0128 162.94 51.9139 171.266 51.9139 180.342V187.915C51.9139 188.197 51.8018 188.467 51.6023 188.667C51.4028 188.866 51.1322 188.978 50.85 188.978C50.5678 188.978 50.2972 188.866 50.0977 188.667C49.8982 188.467 49.7861 188.197 49.7861 187.915V180.342C49.7861 172.187 41.2752 164.225 35.0593 158.41C34.5916 157.972 34.1376 157.547 33.6972 157.132C24.6544 148.603 19.4677 135.9 19.4677 122.28V122.025C19.5386 96.4983 40.3496 75.7785 65.8807 75.7785H66.0122C78.2912 75.7147 90.0926 80.5271 98.8203 89.1571C107.548 97.787 112.487 109.528 112.551 121.796C112.552 121.958 112.552 122.12 112.551 122.282C112.551 136.19 107.632 148.24 98.3262 157.129C97.8265 157.606 97.3063 158.099 96.7708 158.605C90.6359 164.408 82.2313 172.355 82.2313 180.342V187.915C82.2313 188.197 82.1192 188.467 81.9197 188.667C81.7202 188.866 81.4496 188.978 81.1674 188.978H81.1685Z"
          fill={darkShirt}
        />
        <path
          d="M76.1158 202.618H55.9023C55.4321 202.618 54.9811 202.431 54.6485 202.099C54.316 201.767 54.1292 201.316 54.1292 200.846C54.1292 200.376 54.316 199.926 54.6485 199.593C54.9811 199.261 55.4321 199.074 55.9023 199.074H76.1158C76.5861 199.074 77.0371 199.261 77.3696 199.593C77.7021 199.926 77.8889 200.376 77.8889 200.846C77.8889 201.316 77.7021 201.767 77.3696 202.099C77.0371 202.431 76.5861 202.618 76.1158 202.618V202.618Z"
          fill={coloredShirt}
        />
        <path
          d="M68.1825 214.487H63.8323C62.3213 214.488 60.8386 214.078 59.5435 213.3C59.3515 213.188 59.2028 213.015 59.1212 212.808C59.0396 212.602 59.0299 212.374 59.0937 212.161C59.1521 211.943 59.2812 211.75 59.461 211.612C59.6407 211.475 59.861 211.401 60.0872 211.402H71.9277C72.154 211.401 72.3742 211.475 72.554 211.612C72.7338 211.75 72.8629 211.943 72.9213 212.161C72.985 212.374 72.9754 212.602 72.8938 212.808C72.8122 213.015 72.6634 213.188 72.4714 213.3C71.1763 214.078 69.6936 214.488 68.1825 214.487V214.487Z"
          fill={darkShirt}
        />
        <path
          d="M76.1193 208.214L55.7859 208.211L55.7229 208.076C55.6628 207.828 55.6604 207.57 55.7157 207.32C55.771 207.071 55.8826 206.838 56.042 206.638C56.2035 206.434 56.4095 206.268 56.6443 206.155C56.8792 206.042 57.1368 205.983 57.3976 205.983H74.6173C74.8782 205.983 75.1359 206.042 75.3707 206.155C75.6056 206.269 75.8116 206.434 75.9731 206.639C76.1311 206.835 76.2423 207.065 76.2983 207.311C76.3542 207.557 76.3535 207.813 76.2962 208.058L76.2642 208.195L76.1193 208.214Z"
          fill={darkShirt}
        />
        <path
          d="M18.9861 80.5217C18.5575 80.5222 18.1433 80.3669 17.8208 80.0848L2.59365 66.8046C2.24482 66.4941 2.03272 66.0586 2.00346 65.5928C1.97419 65.1269 2.13012 64.6684 2.43734 64.3167C2.74457 63.9651 3.17826 63.7488 3.64419 63.7149C4.11012 63.681 4.57063 63.8321 4.92564 64.1355L20.1528 77.4157C20.4248 77.6529 20.6178 77.9674 20.7061 78.3171C20.7944 78.6669 20.7738 79.0352 20.6469 79.3729C20.5201 79.7106 20.2931 80.0016 19.9963 80.2069C19.6995 80.4123 19.3471 80.5222 18.9861 80.522V80.5217Z"
          fill="#E4E4E4"
        />
        <path
          d="M110.506 80.5219C110.145 80.522 109.793 80.4121 109.496 80.2067C109.199 80.0014 108.972 79.7104 108.846 79.3727C108.719 79.035 108.698 78.6667 108.786 78.317C108.875 77.9672 109.068 77.6527 109.34 77.4156L124.567 64.1343C124.922 63.8309 125.382 63.6797 125.848 63.7137C126.314 63.7476 126.748 63.9639 127.055 64.3155C127.362 64.6672 127.518 65.1257 127.489 65.5916C127.459 66.0574 127.247 66.4929 126.899 66.8034L111.671 80.0836C111.349 80.3661 110.935 80.5219 110.506 80.5219Z"
          fill="#E4E4E4"
        />
        <path
          d="M81.1685 196.33H50.8482C50.378 196.33 49.927 196.144 49.5945 195.811C49.2619 195.479 49.0751 195.029 49.0751 194.559C49.0751 194.089 49.2619 193.638 49.5945 193.306C49.927 192.974 50.378 192.787 50.8482 192.787H81.1685C81.6388 192.787 82.0898 192.974 82.4223 193.306C82.7548 193.638 82.9416 194.089 82.9416 194.559C82.9416 195.029 82.7548 195.479 82.4223 195.811C82.0898 196.144 81.6388 196.33 81.1685 196.33V196.33Z"
          fill={coloredShirt}
        />
        <path
          d="M65.264 150.049L58.9588 187.915H55.3594L61.7639 149.468C61.841 149.004 62.0993 148.59 62.4821 148.317C62.8648 148.043 63.3406 147.933 63.8047 148.01C64.2689 148.087 64.6834 148.345 64.957 148.727C65.2307 149.11 65.3411 149.585 65.264 150.049V150.049Z"
          fill={lightShirt}
        />
        <path
          d="M77.3815 187.915H73.7821L67.4769 150.049C67.3998 149.585 67.5102 149.11 67.7838 148.727C68.0575 148.345 68.472 148.087 68.9362 148.01C69.4003 147.933 69.8761 148.043 70.2588 148.317C70.6416 148.59 70.8999 149.004 70.977 149.468L77.3815 187.915Z"
          fill={lightShirt}
        />
        <path
          d="M54.4851 148.784C54.4851 148.314 54.6719 147.864 55.0044 147.531C55.337 147.199 55.788 147.012 56.2582 147.012H75.76C76.2303 147.012 76.6813 147.199 77.0138 147.531C77.3463 147.864 77.5331 148.314 77.5331 148.784C77.5331 149.254 77.3463 149.704 77.0138 150.037C76.6813 150.369 76.2303 150.556 75.76 150.556H56.2582C55.788 150.556 55.337 150.369 55.0044 150.037C54.6719 149.704 54.4851 149.254 54.4851 148.784V148.784Z"
          fill={lightShirt}
        />
        <path
          d="M66.0091 62.8374C65.5388 62.8374 65.0878 62.6507 64.7553 62.3185C64.4228 61.9862 64.236 61.5356 64.236 61.0658V30.7716C64.236 30.3017 64.4228 29.8511 64.7553 29.5189C65.0878 29.1866 65.5388 29 66.0091 29C66.4793 29 66.9303 29.1866 67.2629 29.5189C67.5954 29.8511 67.7822 30.3017 67.7822 30.7716V61.0658C67.7822 61.5356 67.5954 61.9862 67.2629 62.3185C66.9303 62.6507 66.4793 62.8374 66.0091 62.8374V62.8374Z"
          fill="#E4E4E4"
        />
        <path
          d="M210.951 299.043H206.604L204.535 282.288H210.951L210.951 299.043Z"
          fill="#FEB8B8"
        />
        <path
          d="M212.06 303.254H198.041V303.077C198.041 301.631 198.616 300.244 199.639 299.222C200.662 298.2 202.05 297.625 203.497 297.625H212.059L212.06 303.254Z"
          fill={pantsColor}
        />
        <path
          d="M177.922 298.9L173.722 297.778L176.055 281.058L182.254 282.714L177.922 298.9Z"
          fill="#FEB8B8"
        />
        <path
          d="M177.905 303.254L164.362 299.636L164.408 299.464C164.782 298.068 165.695 296.876 166.948 296.153C168.201 295.429 169.69 295.232 171.088 295.606V295.606L179.36 297.816L177.905 303.254Z"
          fill={pantsColor}
        />
        <path
          d="M213.452 205.145L217.093 209.372L212.384 295.273H202.324L197.252 229.175L181.525 297.046L171.067 294.483L180.576 208.278L213.452 205.145Z"
          fill={pantsColor}
        />
        <path
          d="M183.008 149.685L193.024 144.664L208.428 144.934L221.684 151.711L214.142 189.346L217.4 208.968C204.808 212.177 191.625 212.285 178.982 209.284L178.881 209.26C178.881 209.26 186.368 182.716 183.181 174.615L183.008 149.685Z"
          fill={lightShirt}
        />
        <path
          d="M211.986 129.628C211.975 126.625 210.772 123.749 208.641 121.632C206.51 119.514 203.625 118.328 200.619 118.334C197.614 118.339 194.733 119.536 192.61 121.661C190.487 123.787 189.295 126.667 189.295 129.67C189.295 132.673 190.487 135.553 192.61 137.678C194.733 139.803 197.614 141 200.619 141.006C203.625 141.011 206.51 139.825 208.641 137.708C210.772 135.59 211.975 132.715 211.986 129.712V129.628Z"
          fill="#FEB8B8"
        />
        <path
          d="M181.625 177.124C181.841 177.677 182.175 178.176 182.605 178.587C183.034 178.997 183.549 179.308 184.112 179.498C184.675 179.688 185.273 179.752 185.863 179.687C186.454 179.621 187.023 179.427 187.53 179.118L207.869 189.86L208.527 184.91L188.764 173.266C188.188 172.449 187.331 171.871 186.357 171.643C185.383 171.415 184.359 171.551 183.479 172.027C182.599 172.502 181.925 173.284 181.583 174.223C181.241 175.163 181.256 176.195 181.625 177.124H181.625Z"
          fill="#FEB8B8"
        />
        <path
          d="M208.729 177.979C208.397 178.472 207.962 178.886 207.453 179.192C206.944 179.499 206.374 179.69 205.783 179.752C205.192 179.815 204.594 179.747 204.033 179.554C203.471 179.361 202.958 179.047 202.531 178.634L180.331 184.67L180.525 178.385L202.607 172.654C203.349 171.983 204.311 171.607 205.311 171.597C206.312 171.587 207.281 171.944 208.036 172.6C208.79 173.257 209.277 174.167 209.405 175.159C209.533 176.15 209.292 177.154 208.728 177.98L208.729 177.979Z"
          fill="#FEB8B8"
        />
        <path
          d="M217.355 151.984L221.684 151.713C221.684 151.713 226.751 158.393 223.94 165.643C223.94 165.643 224.427 191.685 213.204 190.615C201.98 189.545 198.434 189.546 198.434 189.546L201.803 180.156L209.34 177.831C209.34 177.831 207.017 167.594 211.414 163.334L217.355 151.984Z"
          fill={lightShirt}
        />
        <path
          d="M186.102 151.755L185.49 148.622C185.49 148.622 176.468 148.409 174.685 162.069C174.685 162.069 166.572 182.51 174.524 185.142C182.475 187.775 191.223 185.142 191.223 185.142L190.564 176.127L181.815 174.344C181.815 174.344 186.335 168.501 183.874 163.434L186.102 151.755Z"
          fill={lightShirt}
        />
        <path
          d="M204.569 135.194C203.734 134.867 202.81 134.884 201.928 134.722C198.774 134.142 196.342 130.929 196.642 127.739C196.717 127.326 196.724 126.903 196.662 126.487C196.443 125.577 195.431 125.088 194.501 124.979C193.57 124.87 192.609 125.01 191.704 124.768C190.307 124.394 189.264 123.121 188.91 121.72C188.608 120.295 188.766 118.811 189.359 117.481L189.793 118.385C190.245 117.906 190.553 117.311 190.682 116.666C191.061 116.686 191.429 116.804 191.749 117.007C192.07 117.21 192.333 117.491 192.514 117.825C193.075 118.075 193.006 116.796 193.565 116.542C193.817 116.482 194.082 116.516 194.31 116.639C195.541 117.052 196.848 116.433 198.068 115.989C200.182 115.222 202.446 114.964 204.679 115.234C206.162 115.415 207.659 115.853 208.814 116.8C209.726 117.625 210.467 118.621 210.997 119.731C212.421 122.392 213.494 125.327 213.465 128.345C213.436 130.815 212.676 133.222 211.279 135.261C210.551 136.319 208.201 139.988 206.639 139.584C204.676 139.078 206.918 136.115 204.569 135.194Z"
          fill={pantsColor}
        />
      </g>
      <path
        fill={thirdBg}
        d="M122.309 487.309L272.993 446.933L383.302 557.242L342.926 707.926L192.242 748.302L81.9329 637.993L122.309 487.309Z"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M187.428 511.907C198.309 503.881 213.629 500.805 225.616 507.066C212.121 512.916 201.134 523.343 194.599 536.503C192.097 541.587 189.844 547.502 184.681 549.852C181.469 551.314 177.678 551.032 174.352 549.851C171.025 548.671 168.066 546.665 165.149 544.681L164.274 544.575C169.466 532.103 176.547 519.933 187.428 511.907Z"
          fill="#F0F0F0"
        />
        <path
          d="M225.519 507.332C214.327 507.682 203.53 511.545 194.662 518.371C192.727 519.806 191.03 521.537 189.635 523.5C188.337 525.457 187.588 527.724 187.465 530.067C187.307 532.249 187.398 534.5 186.711 536.606C186.343 537.678 185.752 538.661 184.977 539.49C184.202 540.319 183.261 540.975 182.214 541.415C179.635 542.578 176.791 542.748 174.004 542.817C170.909 542.893 167.693 542.924 164.879 544.379C164.538 544.555 164.284 544.016 164.624 543.84C169.521 541.309 175.243 542.961 180.389 541.454C182.791 540.751 184.995 539.321 185.959 536.929C186.802 534.837 186.71 532.516 186.852 530.305C186.938 528.018 187.584 525.786 188.733 523.806C190.024 521.778 191.651 519.984 193.544 518.502C197.754 515.096 202.473 512.372 207.53 510.43C213.289 508.181 219.391 506.933 225.572 506.739C225.954 506.727 225.899 507.321 225.519 507.332V507.332Z"
          fill="white"
        />
        <path
          d="M197.388 516.005C196.443 514.364 196.113 512.441 196.457 510.579C196.801 508.718 197.797 507.039 199.266 505.842C199.564 505.601 199.953 506.053 199.655 506.294C198.284 507.406 197.357 508.97 197.04 510.704C196.724 512.438 197.038 514.228 197.927 515.751C198.12 516.082 197.58 516.334 197.388 516.005V516.005Z"
          fill="white"
        />
        <path
          d="M187.4 528.95C191.132 529.992 195.121 529.591 198.571 527.826C198.912 527.651 199.166 528.19 198.825 528.365C195.232 530.194 191.08 530.603 187.199 529.51C186.829 529.406 187.032 528.846 187.4 528.95V528.95Z"
          fill="white"
        />
        <path
          d="M213.261 508.97C213.494 509.681 213.9 510.324 214.443 510.839C214.987 511.355 215.649 511.727 216.372 511.924C216.743 512.023 216.54 512.583 216.171 512.484C215.373 512.263 214.642 511.851 214.041 511.283C213.439 510.714 212.986 510.008 212.722 509.225C212.691 509.153 212.688 509.072 212.714 508.998C212.741 508.925 212.794 508.864 212.864 508.828C212.936 508.795 213.017 508.791 213.092 508.818C213.166 508.844 213.227 508.899 213.261 508.97H213.261Z"
          fill="white"
        />
        <path
          d="M235.12 551.804C234.886 551.748 234.653 551.693 234.415 551.64C231.273 550.909 228.076 550.433 224.856 550.218C224.607 550.197 224.355 550.18 224.106 550.167C216.343 549.732 208.566 550.739 201.172 553.138C198.23 554.096 195.366 555.275 192.603 556.666C188.789 558.588 184.805 560.975 180.698 561.51C180.271 561.571 179.839 561.605 179.407 561.612L164.213 545.649C164.197 545.596 164.177 545.546 164.16 545.492L163.524 544.882C163.662 544.8 163.806 544.719 163.944 544.637C164.023 544.589 164.106 544.545 164.186 544.497C164.24 544.467 164.294 544.436 164.341 544.405C164.359 544.395 164.377 544.385 164.391 544.378C164.439 544.347 164.486 544.324 164.529 544.297C165.334 543.837 166.144 543.383 166.959 542.933C166.963 542.93 166.963 542.93 166.97 542.93C173.186 539.52 179.742 536.598 186.534 534.762C186.738 534.707 186.946 534.648 187.157 534.6C190.223 533.79 193.351 533.231 196.508 532.929C198.237 532.77 199.973 532.698 201.71 532.713C206.197 532.762 210.649 533.516 214.901 534.946C223.378 537.798 230.847 543.326 234.822 551.2C234.923 551.401 235.022 551.599 235.12 551.804Z"
          fill="#F0F0F0"
        />
        <path
          d="M234.883 551.961C225.735 545.512 214.786 542.105 203.589 542.224C201.178 542.207 198.779 542.57 196.482 543.298C194.266 544.08 192.301 545.44 190.79 547.237C189.347 548.884 188.063 550.736 186.244 552.004C185.304 552.639 184.24 553.069 183.121 553.265C182.003 553.461 180.855 553.419 179.754 553.141C176.993 552.52 174.62 550.945 172.353 549.324C169.836 547.525 167.25 545.617 164.126 545.086C163.747 545.022 163.869 544.439 164.247 544.504C169.683 545.426 173.256 550.184 178.274 552.075C180.615 552.958 183.237 553.141 185.449 551.81C187.384 550.647 188.71 548.739 190.156 547.058C191.604 545.284 193.466 543.891 195.578 543C197.831 542.157 200.212 541.703 202.617 541.657C208.032 541.468 213.443 542.131 218.652 543.62C224.606 545.287 230.23 547.958 235.282 551.519C235.595 551.739 235.193 552.18 234.883 551.961L234.883 551.961Z"
          fill="white"
        />
        <path
          d="M207.192 541.974C207.427 540.096 208.323 538.362 209.72 537.082C211.117 535.803 212.925 535.061 214.819 534.989C215.203 534.975 215.241 535.57 214.857 535.583C213.093 535.647 211.41 536.338 210.111 537.532C208.812 538.727 207.984 540.345 207.776 542.095C207.73 542.476 207.146 542.352 207.192 541.974H207.192Z"
          fill="white"
        />
        <path
          d="M191.411 546.306C193.763 549.381 197.19 551.459 201.008 552.123C201.386 552.189 201.264 552.772 200.886 552.706C196.915 552.007 193.353 549.838 190.912 546.632C190.68 546.326 191.18 546.002 191.411 546.306V546.306Z"
          fill="white"
        />
        <path
          d="M224.107 545.899C223.865 546.607 223.802 547.364 223.925 548.102C224.047 548.84 224.352 549.536 224.811 550.128C225.047 550.431 224.547 550.755 224.312 550.454C223.808 549.798 223.473 549.029 223.336 548.214C223.198 547.399 223.263 546.563 223.524 545.778C223.542 545.702 223.589 545.636 223.654 545.593C223.72 545.55 223.799 545.534 223.876 545.547C223.953 545.564 224.021 545.61 224.064 545.676C224.107 545.742 224.123 545.822 224.107 545.899V545.899Z"
          fill="white"
        />
        <path
          d="M184.557 523.847H183.38V491.64C183.38 489.193 182.897 486.769 181.959 484.507C181.021 482.245 179.645 480.191 177.912 478.46C176.178 476.729 174.12 475.356 171.855 474.419C169.59 473.482 167.162 473 164.711 473H96.3715C93.9198 473 91.4922 473.482 89.2272 474.419C86.9621 475.356 84.904 476.729 83.1705 478.46C81.4369 480.191 80.0617 482.245 79.1235 484.507C78.1853 486.769 77.7024 489.192 77.7024 491.64V668.329C77.7024 673.273 79.6693 678.014 83.1704 681.51C86.6716 685.006 91.4201 686.97 96.3714 686.97H164.711C169.662 686.97 174.411 685.006 177.912 681.51C181.413 678.014 183.38 673.273 183.38 668.329V546.772H184.557V523.847Z"
          fill={darkShirt}
        />
        <path
          d="M165.464 477.849H156.544C156.954 478.854 157.11 479.943 156.999 481.022C156.889 482.101 156.514 483.136 155.908 484.036C155.302 484.936 154.484 485.674 153.525 486.184C152.567 486.694 151.497 486.961 150.411 486.961H111.26C110.174 486.961 109.104 486.694 108.146 486.184C107.187 485.674 106.369 484.936 105.763 484.036C105.157 483.136 104.782 482.101 104.671 481.022C104.561 479.943 104.717 478.854 105.127 477.849H96.7956C93.098 477.849 89.5518 479.316 86.9372 481.927C84.3226 484.537 82.8538 488.078 82.8538 491.77V668.199C82.8538 670.027 83.2144 671.837 83.915 673.526C84.6157 675.215 85.6426 676.75 86.9372 678.043C88.2318 679.335 89.7688 680.36 91.4603 681.06C93.1518 681.76 94.9647 682.12 96.7956 682.12H165.464C167.295 682.12 169.108 681.76 170.799 681.06C172.491 680.361 174.028 679.335 175.322 678.043C176.617 676.75 177.644 675.215 178.345 673.526C179.045 671.838 179.406 670.027 179.406 668.199V668.199V491.77C179.406 488.078 177.937 484.537 175.322 481.927C172.708 479.316 169.162 477.849 165.464 477.849Z"
          fill="white"
        />
        <path
          d="M132.449 625.444C127.393 625.449 122.385 624.458 117.712 622.528C113.039 620.598 108.794 617.767 105.218 614.197C101.643 610.627 98.8071 606.387 96.8742 601.722C94.9413 597.057 93.9488 592.056 93.9535 587.007C93.9463 579.918 95.906 572.965 99.6151 566.919C103.324 560.874 108.638 555.972 114.967 552.758C119.554 550.428 124.563 549.038 129.697 548.67C134.831 548.302 139.987 548.963 144.861 550.616C153.493 553.553 160.8 559.454 165.482 567.268C170.164 575.081 171.915 584.301 170.425 593.283C168.935 602.266 164.3 610.43 157.346 616.319C150.391 622.209 141.569 625.442 132.449 625.444L132.449 625.444ZM132.449 550.078C126.609 550.061 120.85 551.44 115.653 554.101C111.111 556.411 107.096 559.635 103.86 563.569C100.625 567.502 98.2385 572.061 96.8516 576.96C95.4647 581.858 95.1069 586.99 95.8008 592.033C96.4946 597.075 98.2251 601.921 100.884 606.264C103.542 610.607 107.071 614.355 111.249 617.272C115.427 620.189 120.164 622.212 125.162 623.214C130.161 624.216 135.313 624.176 140.295 623.095C145.277 622.015 149.981 619.917 154.113 616.935C156.11 615.496 157.957 613.86 159.626 612.051C164.507 606.778 167.742 600.199 168.935 593.119C170.127 586.038 169.227 578.764 166.343 572.188C163.458 565.611 158.716 560.016 152.696 556.089C146.676 552.162 139.64 550.073 132.449 550.078V550.078Z"
          fill="#F0F0F0"
        />
        <path
          d="M49.0348 552.896C50.0914 553.028 51.1062 553.39 52.0076 553.957C52.9089 554.523 53.675 555.28 54.2518 556.173C54.8286 557.067 55.2022 558.076 55.3461 559.13C55.49 560.183 55.4008 561.256 55.0848 562.271L67.875 572.909L63.2964 582.246L45.4455 566.97C43.7722 566.327 42.3934 565.094 41.5702 563.503C40.7471 561.913 40.5367 560.076 40.9792 558.341C41.4216 556.607 42.486 555.094 43.9707 554.09C45.4554 553.087 47.2572 552.662 49.0348 552.896L49.0348 552.896Z"
          fill={lightSkinTone}
        />
        <path
          d="M168.489 597.257C168.233 594.504 167.172 589.555 166.273 587.725C163.506 580.496 145.633 574.554 143.233 573.788L142.624 567.031L117.791 564.363L114.641 573.005L105.734 576.341C105.129 576.566 104.582 576.922 104.131 577.383C103.68 577.844 103.338 578.4 103.127 579.009V579.009L95.1361 581.315L87.2508 583.591L59.856 564.317L58.5626 565.161L49.9176 570.794L48.5839 571.663L84.0352 603.598L97.2043 600.513L107.108 598.187L107.953 601.613L105.764 613.652C112.106 619.986 120.5 623.857 129.442 624.571C138.383 625.284 147.287 622.794 154.555 617.546L160.181 612.562C164.102 608.328 166.996 603.252 168.64 597.725L168.489 597.257Z"
          fill="#E4E4E4"
        />
        <path
          d="M138.01 558.836C147.385 555.737 152.468 545.636 149.364 536.276C146.26 526.916 136.145 521.84 126.77 524.939C117.396 528.038 112.312 538.139 115.416 547.499C118.52 556.859 128.636 561.935 138.01 558.836Z"
          className="light-skin-tone"
        />
        <path
          d="M118.267 521.535C120.005 519.057 122.614 517.322 125.574 516.675C128.501 516.074 131.548 516.632 134.071 518.231C136.814 517.128 139.773 516.396 142.679 516.768C145.585 517.14 148.433 518.761 149.778 521.431C150.061 522.123 150.404 522.788 150.804 523.42C151.989 524.998 154.19 525.203 156.135 525.279C155.152 525.851 154.168 526.424 153.185 526.996C154.165 527.452 155.145 527.908 156.125 528.364C154.791 528.872 153.458 529.381 152.124 529.889C155.228 532.613 155.979 537.462 154.803 541.527C153.691 545.376 151.139 548.615 148.291 551.389C147.735 549.319 148.346 547.076 148.753 544.933C149.183 542.669 149.252 540.022 147.665 538.423C146.594 537.345 145.022 537.019 143.541 536.758C136.069 535.438 128.528 534.544 120.954 534.08C119.94 533.94 118.908 534.045 117.943 534.387C116.1 535.204 115.359 537.45 114.825 539.442C114.569 535.946 114.774 532.432 115.433 528.99C115.939 526.355 116.734 523.719 118.267 521.535Z"
          fill={pantsColor}
        />
        <path
          d="M141.507 643.553H120.372C119.638 643.553 118.934 643.262 118.415 642.744C117.896 642.226 117.604 641.523 117.604 640.79C117.604 640.057 117.896 639.354 118.415 638.836C118.934 638.318 119.638 638.026 120.372 638.026H141.507C142.241 638.026 142.945 638.318 143.464 638.836C143.983 639.354 144.275 640.057 144.275 640.79C144.275 641.523 143.983 642.226 143.464 642.744C142.945 643.262 142.241 643.553 141.507 643.553V643.553Z"
          fill={coloredShirt}
        />
        <path
          d="M72.1552 495.673H14.0218C13.4861 495.672 12.9727 495.459 12.5942 495.08C12.2157 494.702 12.0031 494.189 12.0031 493.654C12.0031 493.119 12.2157 492.606 12.5942 492.227C12.9727 491.849 13.4861 491.636 14.0218 491.635H72.1552C72.6915 491.635 73.2058 491.848 73.585 492.226C73.9642 492.605 74.1772 493.118 74.1772 493.654C74.1772 494.189 73.9642 494.703 73.585 495.081C73.2058 495.46 72.6915 495.673 72.1552 495.673V495.673Z"
          fill={coloredShirt}
        />
        <path
          d="M105.72 508.567H14.0218C13.4856 508.567 12.9713 508.354 12.5922 507.975C12.213 507.597 12 507.083 12 506.548C12 506.013 12.213 505.499 12.5922 505.121C12.9713 504.742 13.4856 504.529 14.0218 504.529H105.72C106.256 504.529 106.77 504.742 107.149 505.121C107.528 505.499 107.741 506.013 107.741 506.548C107.741 507.083 107.528 507.597 107.149 507.975C106.77 508.354 106.256 508.567 105.72 508.567Z"
          fill={coloredShirt}
        />
        <path
          d="M88.0982 520.714H14.0218C13.4856 520.714 12.9714 520.502 12.5922 520.123C12.213 519.745 12 519.231 12 518.696C12 518.16 12.213 517.647 12.5922 517.268C12.9714 516.89 13.4856 516.677 14.0218 516.677H88.0982C88.6344 516.677 89.1487 516.89 89.5278 517.268C89.907 517.647 90.12 518.16 90.12 518.696C90.12 519.231 89.907 519.745 89.5278 520.123C89.1487 520.502 88.6344 520.714 88.0982 520.714V520.714Z"
          fill="#CACACA"
        />
        <path
          d="M55.793 532.817H14.0218C13.4856 532.817 12.9714 532.605 12.5922 532.226C12.213 531.847 12 531.334 12 530.798C12 530.263 12.213 529.75 12.5922 529.371C12.9714 528.992 13.4856 528.78 14.0218 528.78H55.793C56.3292 528.78 56.8434 528.992 57.2226 529.371C57.6018 529.75 57.8148 530.263 57.8148 530.798C57.8148 531.334 57.6018 531.847 57.2226 532.226C56.8434 532.605 56.3292 532.817 55.793 532.817V532.817Z"
          fill="#CACACA"
        />
        <path
          d="M78.8682 544.919H14.0218C13.4862 544.918 12.9727 544.705 12.5943 544.327C12.2158 543.948 12.0032 543.435 12.0032 542.901C12.0032 542.366 12.2158 541.853 12.5943 541.474C12.9727 541.096 13.4862 540.883 14.0218 540.882H78.8682C79.4039 540.883 79.9173 541.096 80.2958 541.474C80.6743 541.853 80.8869 542.366 80.8869 542.901C80.8869 543.435 80.6743 543.948 80.2958 544.327C79.9173 544.705 79.4039 544.918 78.8682 544.919V544.919Z"
          fill="#CACACA"
        />
        <path
          d="M261.623 742.421L256.413 742.421L253.934 722.354L261.624 722.355L261.623 742.421Z"
          fill={darkSkinTone}
        />
        <path
          d="M263.673 749.621L243.781 749.62V749.369C243.781 747.319 244.597 745.353 246.049 743.903C247.501 742.453 249.47 741.639 251.524 741.638H251.524L255.158 738.886L261.937 741.639L263.674 741.639L263.673 749.621Z"
          fill={pantsColor}
        />
        <path
          d="M258.587 648.114L256.289 620.138L254.484 609.638L264.592 607.409L267.946 621.785L265.023 648.89C265.613 649.611 265.973 650.492 266.057 651.419C266.141 652.346 265.946 653.277 265.497 654.092C265.047 654.908 264.363 655.57 263.534 655.995C262.704 656.42 261.766 656.587 260.841 656.475C259.915 656.363 259.044 655.978 258.34 655.368C257.635 654.759 257.129 653.953 256.887 653.054C256.645 652.155 256.677 651.204 256.98 650.324C257.283 649.444 257.843 648.674 258.587 648.114H258.587Z"
          fill={darkSkinTone}
        />
        <path
          d="M233.947 742.421L228.736 742.421L226.258 722.354L233.948 722.355L233.947 742.421Z"
          fill={darkSkinTone}
        />
        <path
          d="M225.608 654.455L223.298 658.445C223.298 658.445 220.76 659.137 221.59 667.397C222.419 675.658 222.455 724.6 226.47 730.622L227.196 735.794L234.778 736.517L234.97 731.929L244.01 668.177C244.01 668.177 249.334 730.761 253.739 732.292L255.33 737.506L262.278 737.509L261.868 731.365L262.316 653.809L225.608 654.455Z"
          fill={pantsColor}
        />
        <path
          d="M223.069 658.141L222.909 658.1C227.676 640.295 226.301 617.24 222.935 592.483L222.935 592.38L235.104 581.251H245.672L256.148 588.001C262.92 591.382 264.459 598.542 264.474 598.614L264.485 598.665L260.335 613.127L263.461 654.952L263.465 655L263.447 655.045C262.435 657.607 259.724 657.242 256.585 656.819C253.451 656.397 241.903 660.255 238.362 660.255C231.442 660.255 223.563 658.267 223.069 658.141Z"
          fill="#E4E4E4"
        />
        <path
          d="M253.954 593.638L258.494 591.666L264.475 598.988L269.323 622.451L252.717 619.939L253.954 593.638Z"
          fill="#E4E4E4"
        />
        <path
          d="M184.129 636.904L204.882 617.969L212.094 610.117L220.045 616.735L210.711 628.181L187.452 642.461C187.242 643.368 186.764 644.191 186.082 644.825C185.399 645.459 184.542 645.874 183.621 646.018C182.7 646.162 181.757 646.028 180.913 645.633C180.069 645.237 179.362 644.599 178.884 643.8C178.406 643.001 178.179 642.077 178.23 641.148C178.282 640.218 178.611 639.325 179.175 638.584C179.739 637.843 180.511 637.287 181.394 636.987C182.277 636.688 183.229 636.658 184.129 636.903L184.129 636.904Z"
          fill={darkSkinTone}
        />
        <path
          d="M204.43 618.175L210.178 610.722C210.225 610.615 214.994 599.975 219.128 595.352C223.368 590.609 226.706 593.034 226.739 593.061L226.823 593.123L227.053 610.636L211.138 629.624L204.43 618.175Z"
          fill="#E4E4E4"
        />
        <path
          d="M248.902 570.768C249.759 565.495 246.173 560.527 240.892 559.671C235.611 558.815 230.635 562.396 229.778 567.669C228.921 572.942 232.507 577.91 237.788 578.766C243.069 579.622 248.045 576.041 248.902 570.768Z"
          fill={darkSkinTone}
        />
        <path
          d="M230.484 567.782C230.484 567.782 230.406 564.382 229.976 562.877C229.545 561.373 228.684 560.083 229.115 560.083C229.545 560.083 229.761 559.009 229.761 559.009C229.761 559.009 230.191 558.794 230.406 559.009C230.622 559.223 230.837 557.504 230.837 557.504C230.837 557.504 231.052 556.859 231.267 557.289C231.483 557.719 231.483 557.504 231.698 556.859C231.913 556.214 232.99 555.784 232.99 555.784C232.99 555.784 237.08 555.999 237.726 555.14C238.371 554.28 245.691 554.925 247.198 555.999C248.705 557.074 255.378 560.943 254.302 564.167C253.225 567.391 250.127 574.815 249.35 575.559C248.122 576.736 247.272 577.16 241.463 578.314C240.326 578.54 234.522 574.835 234.522 574.19C234.522 573.545 235.222 571.5 234.097 571.219C232.397 570.795 232.206 572.941 231.776 572.296C231.345 571.651 230.484 567.782 230.484 567.782Z"
          fill={pantsColor}
        />
        <path
          d="M235.997 749.621L216.104 749.62V749.369C216.105 747.319 216.92 745.353 218.372 743.903C219.824 742.453 221.794 741.639 223.847 741.638H223.848L227.481 738.886L234.261 741.639L235.997 741.639L235.997 749.621Z"
          fill={pantsColor}
        />
        <path
          d="M339.401 750H13.6747C13.5158 750 13.3634 749.937 13.2511 749.825C13.1387 749.712 13.0756 749.56 13.0756 749.401C13.0756 749.243 13.1387 749.091 13.2511 748.978C13.3634 748.866 13.5158 748.803 13.6747 748.803H339.401C339.56 748.803 339.712 748.866 339.825 748.978C339.937 749.091 340 749.243 340 749.401C340 749.56 339.937 749.712 339.825 749.825C339.712 749.937 339.56 750 339.401 750V750Z"
          fill="#CACACA"
        />
      </g>
      <path
        d="M206.54 247.442L423.982 121.902L641.423 247.442L641.423 498.522L423.982 624.062L206.54 498.522L206.54 247.442Z"
        fill={secondBg}
      />
      <path
        d="M304.255 439.411C305.262 431.32 310.278 423.347 317.998 420.714C314.954 429.043 314.954 438.179 318 446.507C319.186 449.715 320.84 453.159 319.724 456.391C319.03 458.403 317.331 459.942 315.433 460.904C313.534 461.866 311.427 462.325 309.346 462.775L308.937 463.113C305.634 455.658 303.248 447.503 304.255 439.411Z"
        fill="#F0F0F0"
      />
      <path
        d="M318.075 420.867C313.579 425.902 310.785 432.226 310.089 438.939C309.913 440.381 309.967 441.843 310.246 443.269C310.564 444.649 311.245 445.919 312.22 446.947C313.109 447.924 314.132 448.82 314.768 449.996C315.085 450.603 315.27 451.27 315.311 451.953C315.352 452.636 315.248 453.32 315.006 453.96C314.444 455.571 313.336 456.884 312.209 458.131C310.957 459.515 309.635 460.932 309.102 462.767C309.038 462.989 308.697 462.876 308.761 462.654C309.687 459.462 312.787 457.649 314.265 454.774C314.955 453.433 315.244 451.875 314.598 450.459C314.032 449.221 312.978 448.296 312.069 447.314C311.103 446.325 310.395 445.115 310.005 443.789C309.654 442.382 309.545 440.926 309.682 439.482C309.94 436.226 310.708 433.032 311.958 430.015C313.366 426.563 315.354 423.379 317.837 420.598C317.99 420.426 318.227 420.696 318.075 420.867L318.075 420.867Z"
        fill="white"
      />
      <path
        d="M310.186 436.764C309.075 436.495 308.096 435.839 307.424 434.915C306.752 433.99 306.43 432.857 306.517 431.717C306.535 431.487 306.895 431.505 306.876 431.735C306.794 432.796 307.093 433.852 307.721 434.711C308.348 435.571 309.262 436.178 310.298 436.423C310.523 436.476 310.409 436.817 310.186 436.764Z"
        fill="white"
      />
      <path
        d="M311.703 446.511C313.71 445.314 315.191 443.404 315.851 441.163C315.917 440.941 316.258 441.054 316.193 441.276C315.501 443.607 313.956 445.591 311.865 446.832C311.666 446.95 311.505 446.629 311.703 446.511Z"
        fill="white"
      />
      <path
        d="M313.7 426.904C314.108 427.098 314.558 427.187 315.009 427.164C315.46 427.141 315.899 427.007 316.285 426.773C316.483 426.652 316.643 426.974 316.447 427.094C316.019 427.35 315.534 427.498 315.036 427.525C314.537 427.551 314.04 427.455 313.587 427.245C313.543 427.229 313.506 427.196 313.485 427.154C313.464 427.112 313.459 427.063 313.473 427.018C313.488 426.973 313.52 426.936 313.563 426.914C313.605 426.893 313.654 426.889 313.7 426.904V426.904Z"
        fill="white"
      />
      <path
        d="M341.529 435.166C341.408 435.245 341.286 435.324 341.165 435.406C339.539 436.475 338.003 437.674 336.571 438.991C336.459 439.091 336.347 439.194 336.238 439.297C332.822 442.508 330.033 446.325 328.012 450.553C327.209 452.236 326.536 453.978 325.997 455.764C325.254 458.23 324.644 460.962 323.172 462.979C323.021 463.192 322.857 463.394 322.681 463.586H309.382C309.352 463.571 309.321 463.559 309.291 463.544L308.76 463.568C308.781 463.474 308.805 463.377 308.827 463.283C308.839 463.228 308.854 463.174 308.866 463.119C308.875 463.083 308.884 463.046 308.89 463.013C308.893 463.001 308.897 462.989 308.9 462.979C308.906 462.946 308.915 462.916 308.921 462.885C309.054 462.342 309.192 461.8 309.333 461.257C309.333 461.254 309.333 461.254 309.336 461.251C310.376 457.103 311.903 453.094 313.888 449.306C313.948 449.194 314.009 449.079 314.076 448.967C314.995 447.29 316.049 445.692 317.228 444.187C317.877 443.365 318.566 442.576 319.295 441.824C321.18 439.884 323.359 438.253 325.751 436.989C330.521 434.472 336.043 433.507 341.141 435.045C341.271 435.085 341.399 435.124 341.529 435.166Z"
        fill="#F0F0F0"
      />
      <path
        d="M341.499 435.335C334.877 436.65 328.837 440.017 324.238 444.959C323.23 446.005 322.392 447.203 321.756 448.51C321.178 449.803 320.957 451.228 321.116 452.635C321.238 453.95 321.515 455.282 321.315 456.603C321.202 457.279 320.948 457.922 320.57 458.492C320.191 459.063 319.696 459.546 319.117 459.912C317.698 460.86 316.023 461.242 314.372 461.558C312.539 461.91 310.629 462.246 309.099 463.39C308.914 463.529 308.709 463.233 308.895 463.095C311.556 461.103 315.123 461.521 318.035 460.115C319.394 459.459 320.563 458.39 320.9 456.87C321.194 455.541 320.91 454.168 320.775 452.838C320.6 451.467 320.763 450.074 321.25 448.781C321.817 447.446 322.607 446.217 323.587 445.147C325.753 442.703 328.291 440.614 331.106 438.958C334.309 437.05 337.815 435.703 341.472 434.977C341.698 434.932 341.724 435.291 341.499 435.335V435.335Z"
        fill="white"
      />
      <path
        d="M325.626 443.28C324.901 442.397 324.514 441.284 324.535 440.141C324.555 438.999 324.981 437.901 325.736 437.043C325.889 436.87 326.166 437.1 326.012 437.274C325.307 438.071 324.91 439.094 324.894 440.158C324.877 441.222 325.241 442.257 325.921 443.076C326.069 443.254 325.772 443.458 325.626 443.28V443.28Z"
        fill="white"
      />
      <path
        d="M320.967 451.976C323.29 452.228 325.623 451.594 327.5 450.203C327.686 450.065 327.89 450.36 327.704 450.498C325.748 451.943 323.32 452.597 320.902 452.33C320.672 452.304 320.738 451.951 320.967 451.976V451.976Z"
        fill="white"
      />
      <path
        d="M334.37 437.522C334.579 437.923 334.885 438.265 335.259 438.518C335.633 438.772 336.064 438.928 336.513 438.974C336.744 438.996 336.678 439.35 336.449 439.327C335.953 439.274 335.477 439.101 335.063 438.822C334.649 438.543 334.31 438.167 334.075 437.727C334.049 437.687 334.039 437.639 334.048 437.593C334.056 437.547 334.082 437.505 334.12 437.477C334.159 437.45 334.208 437.44 334.254 437.448C334.301 437.457 334.343 437.483 334.37 437.522V437.522Z"
        fill="white"
      />
      <path
        d="M543.614 442.273C542.744 435.277 538.407 428.385 531.733 426.109C534.365 433.309 534.364 441.207 531.731 448.407C530.706 451.18 529.276 454.157 530.241 456.952C530.841 458.691 532.309 460.022 533.95 460.853C535.592 461.685 537.413 462.082 539.212 462.47L539.566 462.763C542.422 456.318 544.484 449.268 543.614 442.273Z"
        fill="#F0F0F0"
      />
      <path
        d="M531.666 426.241C535.553 430.594 537.969 436.061 538.57 441.864C538.722 443.112 538.676 444.375 538.434 445.607C538.16 446.801 537.571 447.899 536.728 448.788C535.959 449.632 535.075 450.407 534.525 451.424C534.251 451.948 534.091 452.525 534.056 453.115C534.02 453.706 534.11 454.297 534.32 454.851C534.805 456.243 535.763 457.378 536.738 458.456C537.82 459.652 538.963 460.878 539.423 462.464C539.479 462.656 539.774 462.558 539.718 462.367C538.918 459.607 536.238 458.04 534.96 455.554C534.364 454.394 534.113 453.048 534.672 451.824C535.161 450.753 536.073 449.953 536.859 449.105C537.693 448.25 538.306 447.204 538.642 446.058C538.946 444.841 539.041 443.582 538.922 442.334C538.699 439.519 538.035 436.757 536.954 434.149C535.737 431.166 534.018 428.412 531.872 426.008C531.739 425.859 531.534 426.093 531.666 426.241H531.666Z"
        fill="white"
      />
      <path
        d="M538.487 439.984C539.447 439.751 540.293 439.185 540.874 438.385C541.455 437.586 541.733 436.607 541.658 435.622C541.642 435.422 541.332 435.437 541.348 435.637C541.419 436.554 541.16 437.467 540.618 438.21C540.075 438.953 539.285 439.478 538.389 439.689C538.195 439.736 538.293 440.03 538.487 439.984Z"
        fill="white"
      />
      <path
        d="M537.175 448.411C535.44 447.375 534.159 445.725 533.588 443.787C533.532 443.595 533.237 443.693 533.293 443.885C533.891 445.9 535.227 447.615 537.035 448.688C537.207 448.79 537.346 448.512 537.175 448.411V448.411Z"
        fill="white"
      />
      <path
        d="M535.449 431.46C535.096 431.627 534.707 431.705 534.317 431.685C533.927 431.665 533.548 431.549 533.214 431.347C533.043 431.242 532.904 431.52 533.074 431.624C533.444 431.846 533.863 431.974 534.294 431.997C534.725 432.02 535.155 431.937 535.546 431.755C535.584 431.741 535.616 431.713 535.634 431.676C535.653 431.64 535.657 431.598 535.645 431.559C535.632 431.52 535.604 431.487 535.567 431.469C535.53 431.45 535.488 431.447 535.449 431.46L535.449 431.46Z"
        fill="white"
      />
      <path
        d="M511.39 438.603C511.495 438.671 511.6 438.74 511.705 438.81C513.11 439.734 514.438 440.771 515.676 441.91C515.773 441.996 515.87 442.085 515.964 442.174C518.917 444.95 521.328 448.25 523.076 451.905C523.77 453.36 524.352 454.866 524.817 456.41C525.46 458.542 525.987 460.904 527.259 462.648C527.39 462.831 527.532 463.006 527.684 463.172H539.181C539.208 463.159 539.234 463.149 539.26 463.135L539.719 463.156C539.701 463.075 539.68 462.991 539.661 462.91C539.651 462.863 539.638 462.816 539.627 462.768C539.62 462.737 539.612 462.705 539.606 462.677C539.604 462.666 539.601 462.656 539.599 462.648C539.593 462.619 539.585 462.593 539.58 462.567C539.465 462.097 539.346 461.628 539.223 461.158C539.223 461.156 539.223 461.156 539.221 461.153C538.322 457.568 537.002 454.102 535.286 450.827C535.234 450.73 535.181 450.631 535.123 450.534C534.329 449.084 533.418 447.702 532.398 446.401C531.837 445.691 531.241 445.009 530.612 444.359C528.982 442.682 527.098 441.271 525.03 440.179C520.906 438.003 516.132 437.169 511.725 438.498C511.613 438.532 511.502 438.566 511.39 438.603Z"
        fill="#F0F0F0"
      />
      <path
        d="M511.416 438.749C517.141 439.886 522.362 442.797 526.338 447.068C527.21 447.973 527.934 449.009 528.484 450.139C528.983 451.257 529.174 452.488 529.037 453.705C528.932 454.842 528.692 455.993 528.865 457.135C528.962 457.719 529.182 458.276 529.51 458.768C529.837 459.261 530.265 459.679 530.766 459.995C531.992 460.815 533.44 461.145 534.868 461.419C536.452 461.723 538.103 462.013 539.426 463.003C539.586 463.123 539.763 462.867 539.603 462.747C537.302 461.026 534.218 461.387 531.701 460.172C530.526 459.605 529.515 458.68 529.224 457.366C528.97 456.217 529.216 455.03 529.332 453.88C529.484 452.695 529.342 451.491 528.921 450.373C528.431 449.219 527.748 448.157 526.901 447.231C525.028 445.118 522.835 443.313 520.401 441.881C517.632 440.231 514.601 439.067 511.439 438.439C511.244 438.4 511.221 438.71 511.416 438.749V438.749Z"
        fill="white"
      />
      <path
        d="M525.139 445.618C525.765 444.854 526.099 443.892 526.082 442.904C526.064 441.916 525.696 440.967 525.043 440.225C524.91 440.076 524.672 440.275 524.804 440.425C525.414 441.114 525.757 441.998 525.771 442.918C525.786 443.838 525.471 444.733 524.883 445.441C524.756 445.595 525.012 445.771 525.139 445.618V445.618Z"
        fill="white"
      />
      <path
        d="M529.166 453.135C527.158 453.353 525.141 452.805 523.518 451.602C523.358 451.483 523.181 451.738 523.341 451.858C525.032 453.106 527.132 453.672 529.222 453.441C529.421 453.419 529.364 453.113 529.166 453.135H529.166Z"
        fill="white"
      />
      <path
        d="M517.579 440.64C517.398 440.986 517.134 441.282 516.811 441.501C516.487 441.72 516.115 441.855 515.726 441.894C515.527 441.914 515.583 442.22 515.782 442.2C516.211 442.155 516.622 442.005 516.98 441.764C517.338 441.522 517.631 441.197 517.834 440.817C517.856 440.782 517.865 440.741 517.857 440.701C517.85 440.661 517.828 440.625 517.795 440.601C517.761 440.577 517.719 440.568 517.679 440.576C517.638 440.583 517.602 440.606 517.579 440.64H517.579Z"
        fill="white"
      />
      <path
        d="M567.611 283.132H297.042C296.938 283.132 296.838 283.09 296.764 283.017C296.691 282.943 296.649 282.843 296.649 282.739C296.649 282.635 296.691 282.535 296.764 282.462C296.838 282.388 296.938 282.347 297.042 282.347H567.611C567.715 282.347 567.815 282.388 567.889 282.462C567.963 282.535 568.004 282.635 568.004 282.739C568.004 282.843 567.963 282.943 567.889 283.017C567.815 283.09 567.715 283.132 567.611 283.132V283.132Z"
        fill="#CACACA"
      />
      <path
        d="M305.881 277.636C308.267 277.636 310.201 275.703 310.201 273.318C310.201 270.933 308.267 269 305.881 269C303.495 269 301.561 270.933 301.561 273.318C301.561 275.703 303.495 277.636 305.881 277.636Z"
        fill={darkShirt}
      />
      <path
        d="M320.804 277.636C323.189 277.636 325.123 275.703 325.123 273.318C325.123 270.933 323.189 269 320.804 269C318.418 269 316.484 270.933 316.484 273.318C316.484 275.703 318.418 277.636 320.804 277.636Z"
        fill={darkShirt}
      />
      <path
        d="M335.726 277.636C338.112 277.636 340.046 275.703 340.046 273.318C340.046 270.933 338.112 269 335.726 269C333.34 269 331.406 270.933 331.406 273.318C331.406 275.703 333.34 277.636 335.726 277.636Z"
        fill={darkShirt}
      />
      <path
        d="M558.67 271.641H548.068C547.859 271.641 547.66 271.559 547.512 271.411C547.365 271.264 547.282 271.064 547.282 270.856C547.282 270.648 547.365 270.448 547.512 270.301C547.66 270.154 547.859 270.071 548.068 270.071H558.67C558.774 270.071 558.876 270.091 558.972 270.13C559.067 270.17 559.154 270.227 559.227 270.3C559.3 270.373 559.358 270.46 559.398 270.555C559.437 270.651 559.458 270.753 559.458 270.856C559.458 270.959 559.437 271.062 559.398 271.157C559.358 271.252 559.3 271.339 559.227 271.412C559.154 271.485 559.067 271.543 558.972 271.582C558.876 271.621 558.774 271.641 558.67 271.641H558.67Z"
        fill={darkShirt}
      />
      <path
        d="M558.67 274.585H548.068C547.859 274.585 547.66 274.503 547.512 274.355C547.365 274.208 547.282 274.008 547.282 273.8C547.282 273.592 547.365 273.392 547.512 273.245C547.66 273.098 547.859 273.015 548.068 273.015H558.67C558.879 273.015 559.079 273.098 559.226 273.245C559.373 273.392 559.456 273.592 559.456 273.8C559.456 274.008 559.373 274.208 559.226 274.355C559.079 274.503 558.879 274.585 558.67 274.585H558.67Z"
        fill={darkShirt}
      />
      <path
        d="M558.67 277.529H548.068C547.859 277.529 547.66 277.447 547.512 277.299C547.365 277.152 547.282 276.953 547.282 276.744C547.282 276.536 547.365 276.336 547.512 276.189C547.66 276.042 547.859 275.959 548.068 275.959H558.67C558.774 275.959 558.876 275.979 558.972 276.018C559.067 276.058 559.154 276.116 559.227 276.189C559.3 276.261 559.358 276.348 559.398 276.443C559.437 276.539 559.458 276.641 559.458 276.744C559.458 276.848 559.437 276.95 559.398 277.045C559.358 277.141 559.3 277.227 559.227 277.3C559.154 277.373 559.067 277.431 558.972 277.47C558.876 277.51 558.774 277.53 558.67 277.529H558.67Z"
        fill={darkShirt}
      />
      <path
        d="M559.456 299.965H502.907V333.857H559.456V299.965Z"
        fill={coloredShirt}
      />
      <path
        d="M548.174 349.426H514.528C514.108 349.426 513.705 349.258 513.409 348.961C513.112 348.664 512.946 348.262 512.946 347.842C512.946 347.422 513.112 347.02 513.409 346.723C513.705 346.426 514.108 346.258 514.528 346.258H548.174C548.594 346.258 548.997 346.425 549.295 346.722C549.592 347.019 549.759 347.422 549.759 347.842C549.759 348.262 549.592 348.665 549.295 348.962C548.997 349.259 548.594 349.426 548.174 349.426Z"
        fill="#E4E4E4"
      />
      <path
        d="M542.749 344.003H519.953C519.744 344.004 519.538 343.963 519.345 343.883C519.153 343.804 518.978 343.687 518.83 343.54C518.683 343.393 518.566 343.218 518.486 343.026C518.406 342.834 518.365 342.627 518.365 342.419C518.365 342.211 518.406 342.005 518.486 341.812C518.566 341.62 518.683 341.445 518.83 341.298C518.978 341.151 519.153 341.034 519.345 340.955C519.538 340.875 519.744 340.835 519.953 340.835H542.749C542.958 340.835 543.164 340.875 543.356 340.955C543.549 341.034 543.724 341.151 543.871 341.298C544.019 341.445 544.136 341.62 544.216 341.812C544.296 342.005 544.337 342.211 544.337 342.419C544.337 342.627 544.296 342.834 544.216 343.026C544.136 343.218 544.019 343.393 543.871 343.54C543.724 343.687 543.549 343.804 543.356 343.883C543.164 343.963 542.958 344.004 542.749 344.003Z"
        fill="#E4E4E4"
      />
      <path
        d="M559.456 361.595H502.907V395.487H559.456V361.595Z"
        fill="#F0F0F0"
      />
      <path
        d="M548.174 411.056H514.528C514.108 411.056 513.704 410.889 513.407 410.592C513.11 410.295 512.943 409.892 512.943 409.472C512.943 409.052 513.11 408.649 513.407 408.352C513.704 408.055 514.108 407.888 514.528 407.888H548.174C548.594 407.888 548.998 408.055 549.295 408.352C549.592 408.649 549.759 409.052 549.759 409.472C549.759 409.892 549.592 410.295 549.295 410.592C548.998 410.889 548.594 411.056 548.174 411.056V411.056Z"
        fill="#E4E4E4"
      />
      <path
        d="M542.749 405.634H519.953C519.532 405.634 519.129 405.467 518.832 405.17C518.535 404.873 518.368 404.47 518.368 404.05C518.368 403.629 518.535 403.226 518.832 402.929C519.129 402.632 519.532 402.465 519.953 402.465H542.749C543.17 402.465 543.573 402.632 543.87 402.929C544.167 403.226 544.334 403.629 544.334 404.05C544.334 404.47 544.167 404.873 543.87 405.17C543.573 405.467 543.17 405.634 542.749 405.634Z"
        fill="#E4E4E4"
      />
      <path
        d="M473.91 323.727H303.217C302.796 323.727 302.393 323.561 302.096 323.263C301.799 322.966 301.632 322.563 301.632 322.143C301.632 321.723 301.799 321.32 302.096 321.023C302.393 320.726 302.796 320.559 303.217 320.559H473.91C474.33 320.559 474.733 320.726 475.03 321.023C475.328 321.32 475.495 321.723 475.495 322.143C475.495 322.563 475.328 322.966 475.03 323.263C474.733 323.561 474.33 323.727 473.91 323.727V323.727Z"
        fill="#E4E4E4"
      />
      <path
        d="M473.91 335.73H303.217C302.796 335.73 302.393 335.563 302.096 335.266C301.799 334.969 301.632 334.566 301.632 334.146C301.632 333.726 301.799 333.323 302.096 333.026C302.393 332.728 302.796 332.562 303.217 332.562H473.91C474.33 332.562 474.733 332.728 475.03 333.026C475.328 333.323 475.495 333.726 475.495 334.146C475.495 334.566 475.328 334.969 475.03 335.266C474.733 335.563 474.33 335.73 473.91 335.73V335.73Z"
        fill="#E4E4E4"
      />
      <path
        d="M473.91 347.733H303.217C302.796 347.733 302.393 347.566 302.096 347.269C301.799 346.971 301.632 346.569 301.632 346.148C301.632 345.728 301.799 345.325 302.096 345.028C302.393 344.731 302.796 344.564 303.217 344.564H473.91C474.33 344.564 474.733 344.731 475.03 345.028C475.328 345.325 475.495 345.728 475.495 346.148C475.495 346.569 475.328 346.971 475.03 347.269C474.733 347.566 474.33 347.733 473.91 347.733V347.733Z"
        fill="#E4E4E4"
      />
      <path
        d="M473.91 359.735H303.217C302.796 359.735 302.393 359.568 302.096 359.271C301.799 358.974 301.632 358.571 301.632 358.151C301.632 357.731 301.799 357.328 302.096 357.031C302.393 356.734 302.796 356.567 303.217 356.567H473.91C474.33 356.567 474.733 356.734 475.03 357.031C475.328 357.328 475.495 357.731 475.495 358.151C475.495 358.571 475.328 358.974 475.03 359.271C474.733 359.568 474.33 359.735 473.91 359.735V359.735Z"
        fill="#E4E4E4"
      />
      <path
        d="M473.91 383.74H303.217C302.796 383.74 302.393 383.573 302.096 383.276C301.799 382.979 301.632 382.576 301.632 382.156C301.632 381.736 301.799 381.333 302.096 381.036C302.393 380.739 302.796 380.572 303.217 380.572H473.91C474.33 380.572 474.733 380.739 475.03 381.036C475.328 381.333 475.495 381.736 475.495 382.156C475.495 382.576 475.328 382.979 475.03 383.276C474.733 383.573 474.33 383.74 473.91 383.74V383.74Z"
        fill="#E4E4E4"
      />
      <path
        d="M473.91 395.743H303.217C302.796 395.743 302.393 395.576 302.096 395.279C301.799 394.982 301.632 394.579 301.632 394.159C301.632 393.738 301.799 393.336 302.096 393.038C302.393 392.741 302.796 392.574 303.217 392.574H473.91C474.33 392.574 474.733 392.741 475.03 393.038C475.328 393.336 475.495 393.738 475.495 394.159C475.495 394.579 475.328 394.982 475.03 395.279C474.733 395.576 474.33 395.743 473.91 395.743V395.743Z"
        fill="#E4E4E4"
      />
      <path
        d="M445.713 311.683H331.414C331.205 311.683 330.999 311.642 330.806 311.563C330.614 311.483 330.439 311.367 330.291 311.219C330.144 311.072 330.027 310.898 329.947 310.705C329.867 310.513 329.826 310.307 329.826 310.098C329.826 309.89 329.867 309.684 329.947 309.492C330.027 309.299 330.144 309.124 330.291 308.977C330.439 308.83 330.614 308.713 330.806 308.634C330.999 308.555 331.205 308.514 331.414 308.514H445.713C445.921 308.514 446.127 308.555 446.32 308.634C446.513 308.713 446.688 308.83 446.835 308.977C446.982 309.124 447.099 309.299 447.179 309.492C447.259 309.684 447.3 309.89 447.3 310.098C447.3 310.307 447.259 310.513 447.179 310.705C447.099 310.898 446.982 311.072 446.835 311.219C446.688 311.367 446.513 311.483 446.32 311.563C446.127 311.642 445.921 311.683 445.713 311.683V311.683Z"
        fill="#E4E4E4"
      />
      <path
        d="M489.508 460.541H492.808L494.378 447.816L489.508 447.817L489.508 460.541Z"
        className="light-skin-tone"
      />
      <path
        d="M488.667 459.464L495.166 459.463H495.166C496.264 459.463 497.318 459.9 498.095 460.676C498.871 461.452 499.308 462.505 499.308 463.603V463.738L488.667 463.738L488.667 459.464Z"
        fill={pantsColor}
      />
      <path
        d="M472.641 460.541H475.941L477.511 447.816L472.641 447.817L472.641 460.541Z"
        className="light-skin-tone"
      />
      <path
        d="M471.799 459.464L478.298 459.463H478.299C479.397 459.463 480.451 459.9 481.227 460.676C482.004 461.452 482.44 462.505 482.44 463.603V463.738L471.8 463.738L471.799 459.464Z"
        fill={pantsColor}
      />
      <path
        d="M511.177 318.749C511.178 318.196 511.055 317.649 510.819 317.148C510.582 316.648 510.238 316.205 509.81 315.854C509.382 315.502 508.881 315.25 508.344 315.114C507.807 314.979 507.247 314.965 506.703 315.072C506.16 315.179 505.647 315.405 505.202 315.734C504.756 316.063 504.389 316.487 504.127 316.975C503.865 317.463 503.714 318.002 503.686 318.555C503.657 319.108 503.752 319.661 503.962 320.173L496.379 332.167L491.657 341.678L495.553 345.134L498.552 341.98L508.357 322.376C509.163 322.17 509.878 321.702 510.389 321.046C510.899 320.389 511.177 319.581 511.177 318.749V318.749Z"
        className="light-skin-tone"
      />
      <path
        d="M471.82 358.091L464.387 356.94L462.616 370.383L452.968 391.137C452.338 391.529 451.839 392.099 451.534 392.774C451.229 393.45 451.13 394.201 451.252 394.932C451.373 395.663 451.708 396.342 452.216 396.883C452.723 397.423 453.379 397.802 454.101 397.97C454.824 398.138 455.58 398.088 456.273 397.826C456.967 397.565 457.568 397.104 458 396.501C458.432 395.899 458.675 395.182 458.699 394.441C458.724 393.7 458.527 392.969 458.136 392.339L468.224 374.13L471.82 358.091Z"
        className="light-skin-tone"
      />
      <path
        d="M475.94 380.487C475.94 380.487 469.403 386.529 470.233 401.723L472.065 425.195L471.603 457.914L478.317 457.387L484.183 402.554L489.805 425.035L487.787 454.982L494.255 455.132L501.05 421.288L499.44 396.201L496.734 380.487H475.94Z"
        fill={pantsColor}
      />
      <path
        d="M482.934 345.102L467.316 349.474L475.257 373.257L474.07 382.356L498.151 382.512L492.574 364.926C492.574 364.926 495.703 358.492 492.13 353.983L482.934 345.102Z"
        fill={coloredShirt}
      />
      <path
        d="M481.06 348.849L482.934 345.102C482.934 345.102 482.873 344.161 487.276 343.695L492.443 338.562L497.172 343.273L489.181 355.094L481.06 348.849Z"
        fill={coloredShirt}
      />
      <path
        d="M473.563 352.596C473.563 352.596 467.941 346.351 464.817 349.474C461.693 352.596 463.295 362.525 463.295 362.525L473.024 364.034L473.563 352.596Z"
        fill={coloredShirt}
      />
      <path
        d="M476.415 328.592C485.99 328.635 485.989 343.188 476.414 343.229C466.84 343.186 466.84 328.633 476.415 328.592Z"
        className="light-skin-tone"
      />
      <path
        d="M477.481 335.427C478.775 336.189 480.12 336.968 481.613 337.131C483.106 337.295 484.796 336.666 485.396 335.29C485.583 334.812 485.682 334.305 485.689 333.793C485.82 331.178 484.915 328.617 483.17 326.664C482.294 325.708 481.222 324.951 480.026 324.446C478.831 323.94 477.541 323.699 476.244 323.737C473.77 323.845 471.484 325.016 469.289 326.161C467.27 327.214 465.138 328.375 464.041 330.37C462.415 333.326 463.6 336.945 463.916 340.303C464.138 342.808 463.852 345.331 463.077 347.722C462.301 350.114 461.052 352.325 459.403 354.223L473.026 355.513C474.684 355.67 476.375 355.826 478 355.463C479.402 355.15 480.693 354.463 481.736 353.475C482.779 352.487 483.534 351.235 483.922 349.852C484.31 348.469 484.315 347.007 483.938 345.621C483.561 344.236 482.816 342.978 481.781 341.982C479.847 340.121 476.737 338.803 476.661 336.121"
        fill={pantsColor}
      />
      <path
        d="M579.009 460.236H575.394L573.675 446.3H579.009L579.009 460.236Z"
        fill={darkSkinTone}
      />
      <path
        d="M579.93 463.738L568.276 463.738V463.591C568.276 462.388 568.754 461.235 569.605 460.384C570.455 459.534 571.609 459.056 572.812 459.056H572.813L579.931 459.056L579.93 463.738Z"
        fill={pantsColor}
      />
      <path
        d="M612.618 460.236H609.004L607.284 446.3H612.619L612.618 460.236Z"
        fill={darkSkinTone}
      />
      <path
        d="M613.54 463.738L601.885 463.738V463.591C601.886 462.388 602.363 461.235 603.214 460.384C604.065 459.534 605.219 459.056 606.422 459.056H606.422L613.54 459.056L613.54 463.738Z"
        fill={pantsColor}
      />
      <path
        d="M577.568 389.513C577.568 389.513 574.534 397.745 574.968 401.211C575.401 404.677 571.934 428.939 572.367 431.105C572.801 433.271 571.934 444.102 572.367 445.835C572.801 447.568 571.151 449.638 572.018 451.371C572.884 453.104 574.101 456.666 574.101 456.666L579.735 456.233L580.602 451.467C580.602 451.467 583.202 451.034 582.769 448.435C582.336 445.835 584.936 428.505 584.936 428.505L592.304 404.244L602.706 426.339C602.706 426.339 603.139 441.936 604.439 443.669C605.74 445.402 603.139 450.168 604.439 451.901C605.74 453.634 607.04 456.666 607.04 456.666H613.541V453.2C613.541 453.2 617.442 451.034 613.541 444.535L610.905 405.508L608.305 387.745L577.568 389.513Z"
        fill={pantsColor}
      />
      <path
        d="M593.725 335.037C598.196 335.037 601.82 331.414 601.82 326.945C601.82 322.476 598.196 318.853 593.725 318.853C589.254 318.853 585.63 322.476 585.63 326.945C585.63 331.414 589.254 335.037 593.725 335.037Z"
        fill="#A0616A"
      />
      <path
        d="M586.836 326.387C587.407 327.846 587.979 329.305 588.55 330.763C589.252 332.557 590.056 334.476 591.702 335.477C593.735 336.712 596.449 336.11 598.415 334.771C600.183 333.567 601.54 331.851 602.304 329.853C603.068 327.856 603.203 325.672 602.689 323.596C602.175 321.52 601.038 319.651 599.431 318.239C597.824 316.828 595.823 315.942 593.697 315.701L593.615 317.166L591.817 315.659C591.684 315.887 591.501 316.082 591.282 316.229C591.063 316.377 590.813 316.472 590.552 316.509C590.29 316.546 590.024 316.524 589.772 316.443C589.521 316.362 589.291 316.225 589.1 316.043C589.572 317.022 589.027 318.216 588.24 318.965C587.272 319.886 584.506 320.993 584.621 322.64C584.697 323.739 586.39 325.248 586.836 326.387Z"
        fill={pantsColor}
      />
      <path
        d="M616.008 401.483C616.287 401.067 616.471 400.595 616.548 400.1C616.624 399.605 616.591 399.099 616.451 398.618C616.311 398.137 616.068 397.693 615.737 397.316C615.407 396.94 614.998 396.64 614.539 396.439L614.701 388.611L609.966 387.352L609.893 398.41C609.605 399.203 609.619 400.074 609.93 400.857C610.242 401.641 610.831 402.283 611.585 402.662C612.338 403.041 613.205 403.13 614.02 402.913C614.835 402.696 615.543 402.187 616.008 401.483V401.483Z"
        fill={darkSkinTone}
      />
      <path
        d="M599.738 340.038C599.738 340.038 593.465 336.903 587.541 338.993C581.616 341.083 578.48 343.522 578.48 343.522L582.313 363.727L577.086 390.9C577.086 390.9 603.92 388.81 606.36 390.9C608.799 392.99 608.799 388.461 608.799 388.461L606.708 362.682L610.542 346.309L599.738 340.038Z"
        fill={darkShirt}
      />
      <path
        d="M573.262 401.947C573.647 401.626 573.956 401.224 574.169 400.771C574.381 400.317 574.492 399.823 574.493 399.322C574.493 398.821 574.384 398.326 574.173 397.872C573.961 397.417 573.653 397.015 573.269 396.693L575.623 389.225L571.432 386.688L568.256 397.281C567.758 397.961 567.526 398.801 567.605 399.641C567.684 400.48 568.069 401.262 568.686 401.837C569.303 402.412 570.11 402.741 570.953 402.761C571.796 402.781 572.618 402.492 573.262 401.947V401.947Z"
        fill={darkSkinTone}
      />
      <path
        d="M580.919 343.522H578.48C578.48 343.522 574.646 345.961 574.646 349.444C574.646 352.928 569.07 393.687 569.07 393.687L574.995 394.732L580.222 366.166L584.056 358.502L580.919 343.522Z"
        fill={darkShirt}
      />
      <path
        d="M603.92 346.309H610.542C610.542 346.309 612.633 348.399 613.678 353.276C614.724 358.153 615.769 377.314 615.769 377.314L615.072 394.732H609.845L609.148 372.088L605.663 357.457L603.92 346.309Z"
        fill={darkShirt}
      />
      <path
        d="M296.988 369.492C296.821 369.573 296.66 369.667 296.508 369.773L281.006 363.715L280.391 359.72L274.239 359.859L274.639 367.037C274.671 367.617 274.891 368.171 275.265 368.616C275.639 369.06 276.147 369.371 276.713 369.503L295.159 373.78C295.385 374.459 295.811 375.053 296.383 375.484C296.954 375.914 297.643 376.16 298.358 376.19C299.072 376.219 299.779 376.031 300.384 375.649C300.989 375.267 301.463 374.71 301.744 374.052C302.024 373.394 302.098 372.667 301.955 371.966C301.811 371.265 301.458 370.625 300.942 370.13C300.426 369.635 299.771 369.308 299.064 369.194C298.358 369.08 297.634 369.184 296.988 369.492H296.988Z"
        fill={darkSkinTone}
      />
      <path
        d="M273.712 459.751L277.827 459.751L279.784 443.886H273.712L273.712 459.751Z"
        fill={darkSkinTone}
      />
      <path
        d="M272.663 458.409L280.766 458.408H280.766C282.136 458.408 283.449 458.952 284.418 459.92C285.386 460.888 285.93 462.201 285.93 463.57V463.738L272.663 463.738L272.663 458.409Z"
        fill={pantsColor}
      />
      <path
        d="M253.911 459.751L258.025 459.751L259.983 443.886H253.91L253.911 459.751Z"
        fill={darkSkinTone}
      />
      <path
        d="M252.861 458.409L260.964 458.408H260.965C262.334 458.408 263.648 458.952 264.616 459.92C265.585 460.888 266.129 462.201 266.129 463.57V463.738L252.861 463.738L252.861 458.409Z"
        fill={pantsColor}
      />
      <path
        d="M256.297 338.794C255.125 333.958 254.132 328.503 256.342 324.048C257.875 320.957 263.548 319.916 266.8 321.03C270.051 322.143 272.402 325.164 273.436 328.458C274.47 331.752 274.35 335.301 273.9 338.726C267.919 338.611 261.939 338.496 256.297 338.794Z"
        fill={pantsColor}
      />
      <path
        d="M266.389 338.793C270.24 338.793 273.363 335.672 273.363 331.822C273.363 327.972 270.24 324.851 266.389 324.851C262.537 324.851 259.415 327.972 259.415 331.822C259.415 335.672 262.537 338.793 266.389 338.793Z"
        fill={darkSkinTone}
      />
      <path
        d="M257.863 330.386C257.841 329.213 258.055 328.047 258.493 326.958C258.93 325.869 259.582 324.879 260.41 324.047C261.237 323.215 262.224 322.557 263.311 322.114C264.398 321.67 265.563 321.45 266.737 321.465C267.91 321.481 269.069 321.732 270.144 322.204C271.219 322.676 272.188 323.359 272.993 324.213C273.799 325.066 274.424 326.073 274.833 327.173C275.241 328.273 275.425 329.444 275.372 330.617C269.402 330.629 263.433 330.642 257.863 330.386Z"
        fill={pantsColor}
      />
      <path
        d="M260.601 364.512L257.684 369.371C257.684 369.371 249.697 384.346 250.172 396.759L251.94 412.22L250 416.014L251.298 422.689L248.887 427.471L253.246 450.557L253.431 455.563H260.48L260.109 450.464L263.077 427.974L268.086 403.961L270.683 424.69L269.755 429.882L270.961 434.239L272.63 447.683L272.816 454.172L279.216 454.914L281.627 430.901L283.018 419.444L275.562 368.644L260.601 364.512Z"
        fill={pantsColor}
      />
      <path
        d="M259.676 341.05L255.334 342.893L252.895 352.982L258.03 366.127L256.11 372.695C261.921 382.631 269.113 384.074 277.576 377.703C275.785 372.676 276.044 367.507 277.829 362.232C277.829 362.232 282.281 358.088 279.171 352.7L276.204 344.651L272.531 342.815L259.676 341.05Z"
        fill="#CBCBCB"
      />
      <path
        d="M275.349 346.406L276.334 344.576C276.334 344.576 278.934 344.967 279.996 348.095C281.057 351.223 281.333 363.86 281.333 363.86L275.771 364.142L275.349 346.406Z"
        fill="#CBCBCB"
      />
      <path
        d="M411.155 346.257L245.863 388.84C245.457 388.943 245.026 388.881 244.665 388.667C244.305 388.454 244.043 388.106 243.939 387.7C243.834 387.294 243.895 386.863 244.107 386.502C244.319 386.141 244.666 385.878 245.072 385.772L410.364 343.189C410.566 343.137 410.776 343.124 410.983 343.153C411.189 343.182 411.388 343.251 411.568 343.357C411.747 343.462 411.905 343.602 412.03 343.769C412.156 343.935 412.247 344.125 412.299 344.327C412.351 344.529 412.363 344.739 412.333 344.945C412.304 345.151 412.234 345.35 412.128 345.529C412.022 345.709 411.881 345.865 411.714 345.99C411.547 346.115 411.357 346.206 411.155 346.257V346.257Z"
        fill={coloredShirt}
      />
      <path
        d="M253.559 389.297C254.03 389.062 254.445 388.727 254.773 388.314C255.102 387.902 255.335 387.423 255.458 386.911C255.581 386.398 255.59 385.865 255.485 385.349C255.379 384.833 255.162 384.346 254.848 383.923L257.544 357.573H251.717L249.575 383.302C248.899 383.877 248.457 384.679 248.333 385.558C248.209 386.436 248.412 387.329 248.903 388.068C249.394 388.807 250.139 389.34 250.997 389.567C251.855 389.793 252.767 389.697 253.559 389.297V389.297Z"
        fill={darkSkinTone}
      />
      <path
        d="M258.882 345.873L256.84 342.988C256.84 342.988 253.25 340.554 251.419 346.155C249.588 351.756 249.87 362.131 249.87 362.131L256.84 365.017L258.882 345.873Z"
        fill="#CBCBCB"
      />
      <path
        d="M369.163 464H211.414C211.304 464 211.199 463.956 211.121 463.879C211.044 463.801 211 463.696 211 463.586C211 463.476 211.044 463.371 211.121 463.293C211.199 463.216 211.304 463.172 211.414 463.172H369.163C369.273 463.172 369.378 463.216 369.456 463.293C369.533 463.371 369.577 463.476 369.577 463.586C369.577 463.696 369.533 463.801 369.456 463.879C369.378 463.956 369.273 464 369.163 464Z"
        fill="#CACACA"
      />
      <path
        d="M617.586 464H459.837C459.727 464 459.622 463.956 459.544 463.879C459.467 463.801 459.423 463.696 459.423 463.586C459.423 463.476 459.467 463.371 459.544 463.293C459.622 463.216 459.727 463.172 459.837 463.172H617.586C617.696 463.172 617.801 463.216 617.879 463.293C617.956 463.371 618 463.476 618 463.586C618 463.696 617.956 463.801 617.879 463.879C617.801 463.956 617.696 464 617.586 464Z"
        fill="#CACACA"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="233"
            height="275"
            fill="white"
            transform="translate(2 29)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="328"
            height="277"
            fill="white"
            transform="translate(12 473)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CareersFeelCollage;
