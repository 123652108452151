import React, { Component } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import * as styles from './card.module.css'

class Card extends Component {
  render() {
    const { className, linkTo, children, style, rippleEffect } = this.props

    const classes = {
      [styles.card]: true,
      [className]: className,
    }

    function createRipple(event) {
      const button = event.currentTarget

      // prevent multiple ripples being added to the same button
      const ripple = button.getElementsByClassName(styles.ripple)[0]
      if (ripple) {
        return
      }

      // Create Circle for ripple
      const circle = document.createElement('span')
      const diameter = Math.max(button.clientWidth, button.clientHeight)
      const radius = diameter / 2

      // Figure out position to place circle for animation
      const topPos = button.getBoundingClientRect().top
      const leftPos = button.getBoundingClientRect().left

      circle.style.width = circle.style.height = `${diameter}px`
      circle.style.left = `${event.clientX - leftPos - radius}px`
      circle.style.top = `${event.clientY - topPos - radius}px`

      circle.classList.add(styles.ripple)

      // Add Circle span to element
      button.appendChild(circle)
    }

    function removeRipple(event) {
      const button = event.currentTarget
      const ripple = button.getElementsByClassName(styles.ripple)[0]

      if (ripple) {
        ripple.remove()
      }
    }

    if (rippleEffect) {
      return (
        <Link
          to={linkTo}
          className={classNames(classes)}
          onMouseEnter={createRipple}
          onMouseLeave={removeRipple}
        >
          {children}
        </Link>
      )
    } else if (linkTo) {
      return (
        <Link to={linkTo} className={classNames(classes)} style={style}>
          {children}
        </Link>
      )
    }

    return (
      <div className={classNames(classes)} style={style}>
        {children}
      </div>
    )
  }
}

export default Card
