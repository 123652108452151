import React from 'react'
import { Link } from 'gatsby'
import Button from '../button'
import * as styles from './callout.module.css'
import HexagonIcon from '../icons/Hexagon'

const classes = [styles.spinUp, styles.spinDown]

const Callout = ({ data, slug }) => {
  const linkTo = slug
    ? `${data.buttonLink.slug}?for=${slug}`
    : data.buttonLink.slug

  // @todo consider whether this could be managed by GTM instead
  const trackCalloutClick = () => {
    if (window.analytics) {
      window.analytics.track(`Callout button clicked`)
    }
  }

  return (
    <div className={styles.calloutWrapper}>
      <div className={styles.callout}>
        <h4 className={styles.calloutHeader}>{data.header}</h4>
        {data.subheader && (
          <div
            dangerouslySetInnerHTML={{ __html: data.subheader.child.html }}
            className={styles.calloutSubheader}
          />
        )}
        <Link
          onClick={trackCalloutClick}
          to={linkTo}
          className={styles.calloutButtonWrapper}
        >
          <Button className={styles.calloutButton}>
            <span>{data.button}</span>
          </Button>
        </Link>
      </div>
      <div className={styles.calloutAnimation}>
        <HexagonIcon
          size={Math.floor(Math.random() * 24) + 18}
          className={classes[Math.floor(Math.random() * classes.length)]}
          style={{
            top: `-${Math.floor(Math.random() * 5) + 1}px`,
            left: `${Math.floor(Math.random() * 20) + 10}px`,
            order: `${Math.floor(Math.random() * 4) + 1}`,
            animationDuration: `${Math.floor(Math.random() * 24) + 10}s`,
          }}
        />
        <HexagonIcon
          size={Math.floor(Math.random() * 32) + 24}
          className={classes[Math.floor(Math.random() * classes.length)]}
          style={{
            top: `${Math.floor(Math.random() * 12) + 1}px`,
            left: `${Math.floor(Math.random() * 30) + 1}px`,
            order: `${Math.floor(Math.random() * 4) + 1}`,
            animationDuration: `${Math.floor(Math.random() * 20) + 10}s`,
          }}
        />
        <HexagonIcon
          size={Math.floor(Math.random() * 48) + 32}
          className={classes[Math.floor(Math.random() * classes.length)]}
          style={{
            top: `-${Math.floor(Math.random() * 15) + 1}px`,
            left: `${Math.floor(Math.random() * 30) + 10}px`,
            order: `${Math.floor(Math.random() * 4) + 1}`,
            animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
          }}
        />
        <HexagonIcon
          size={Math.floor(Math.random() * 32) + 18}
          className={classes[Math.floor(Math.random() * classes.length)]}
          style={{
            top: `-${Math.floor(Math.random() * 15) + 1}px`,
            left: `${Math.floor(Math.random() * 20) + 1}px`,
            order: `${Math.floor(Math.random() * 4) + 1}`,
            animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
          }}
        />
      </div>
    </div>
  )
}

export default Callout
